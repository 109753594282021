import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";

export default function Userlayout() {
  const [showmenu, setShowmenu] = useState(false);
  return (
    // <div className="container-fluid">
    //   <div className="row flex-nowrap">
    //     <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
    //       <Sidebar />
    //     </div>
    //     <div className="col py-3">
    //       <NavBar />
    //       <Outlet />
    //     </div>
    //   </div>
    // </div>

    <div className="page-wrapper">
      <NavBar setShowmenu={setShowmenu} showmenu={showmenu} />
      <main id="page-main">
        <div className="page-inner-wrapper d-flex">
          <Sidebar setShowmenu={setShowmenu} showmenu={showmenu} />
          <div className="page-inner-content w-100 bg-light">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
