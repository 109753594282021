import React from "react";
import { Routes, Route } from "react-router-dom";
import Userlayout from "../Components/User/Layout/Userlayout";
import UsersTrainings from "../Pages/UsersTrainings";

export default function AdminPrivateRoutes() {
    return (
      <Routes>
      </Routes>
    )
}
    