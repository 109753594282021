import { React, useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, FormFeedback } from "reactstrap";
import { Select } from "react-select-virtualized";
import helper from "../../Helper/Helper";
import { validateAll } from "indicative/validator";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import moment from "moment";
import { updateTotalCoins } from "../../Action/Auth.action";
import { Link } from "react-router-dom";

export default function AddSessionForm(props) {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disablebutton, setDisablebutton] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [minUpdateEventDate, setMinUpdateEventDate] = useState(moment.utc(new Date()).add(1, 'd').format());
  const [cost, setCost] = useState("");
  const auth = useSelector((user) => user.auth);
  const [state, setState] = useState({
    sessionName: "",
    timeStart: "",
    timeEnd: "",
    utctimeStart: "",
    utctimeEnd: "",
    // zoomLink: auth.default_zoom_link ? auth.default_zoom_link : "",
    zoomLink: "https://zoom.us/j/700700700700",
    template_id: props.templeteid,
    reservation_id: props.reservation_id,
    duration: "",
    errors: {},
  });
  const { sessionName, timeStart, timeEnd, template_id, reservation_id, utctimeStart, utctimeEnd, duration, zoomLink, errors } = state;
  const [startDate, setStartDate] = useState(
    new Date().setHours(new Date().setMinutes(new Date(), 30), 16)
  );

  const [endDate, setEndDate] = useState(
    new Date().setHours(new Date().setMinutes(new Date(), 30), 16)
  );

  const [initialDuration, setInitialDuration] = useState(false);

  const getTotalCost = (minute) => {
    // 20 credits per 30mins
    return minute * 20 / 30;
  }

  const onChangeValue = (e) => {
    let value = e.target.value;
    if (e.target.name === 'duration') {
      let duration_array = e.target.value.split(" ");
      if (duration_array[0] > 0) {
        let cost_time = getTotalCost(duration_array[0]);
        setCost(cost_time)
        if(cost_time > auth.total_coin){
          setDisablebutton(true)
        }else{
          setDisablebutton(false)
        }
      } else {
        setCost("")
      }
    }
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onChange = (date, type) => {

    if (type === "startDate") {
      setStartDate(date);
      let sDate = format(date, "yyyy-MM-dd HH:mm");
      setState({
        ...state,
        timeStart: sDate,
        utctimeStart: moment.utc(date).format()
      });
    } else {
      if (type === "endDate") {
        let eDate = format(date, "yyyy-MM-dd HH:mm");
        setEndDate(date);
        setState({
          ...state,
          timeEnd: eDate,
          utctimeEnd: moment.utc(date).format()
        });
      }
    }
  };

  const resetForm = () => {

    setStartDate("");
    setCost("")
    setEndDate("");
    setState({
      sessionName: "",
      timeStart: "",
      timeEnd: "",
      zoomLink: auth.default_zoom_link ? auth.default_zoom_link : "",
      reservation_id: "",
      duration: "",
      errors: {}
    });

    if (props.formType === "sessionsForm") {
      props.setAnimationVideo("")
      props.setTemplate_name("")
    }
    props.settempleteid("")
    props.setReservation_id("")
    props.setModal(false);

  };

  const submitSessionForm = (e) => {



    e.preventDefault();
    const rules = {
      sessionName: "required",
      timeStart: "required",
      duration: "required",
      zoomLink: "url",
      template_id: "required",

    };

    const message = {
      "sessionName.required": "Session Name is Required.",
      "template_id.required": "Virtualization is Required.",
      "timeStart.required": "Time Start is Required.",
      "duration.required": "Duration is Required.",
      "zoomLink.required": "DeepTherapy Link is Required.",
      "zoomLink.url": "Enter a valid URL.",
    };

    validateAll(state, rules, message)
      .then(async () => {
        setLoading(true);
        setErrormessage("")
        let data = {};
        let duration_array = duration.split(" ");

        let end_time = moment.utc(utctimeStart).add(duration_array[0], duration_array[1]).format();
        data.user_id = auth.userDeatils.user_id;
        data.template_id = template_id;
        data.name = sessionName;
        data.time_start = utctimeStart;
        data.time_end = end_time;
        data.zoom_link = zoomLink;
        data.duration = duration_array[0];
        let url = "reservations/create";

        if (reservation_id) {
          data.reservation_id = reservation_id;
          url = "reservations/edit"
        }

        // data.time_zone = helper.userTimezone;

        AxiosApi.withToken(
          auth.accessToken,
          url,
          "post",
          data
        )
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              resetForm();
              dispatch(updateTotalCoins(res.data))
              props.setIsUpdate(!props.isUpdate);
            } else {
              setErrormessage(res.message)
              // toast.error(res.message, {
              //   position: "top-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              // });
            }
            setLoading(false);
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        console.log(errors)
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };

  const videoRef = useRef();
  const getreservation = async (reservation_id) => {
    await AxiosApi.withToken(auth.accessToken, "reservations/" + reservation_id + "/details", "get", {})
      .then(async (res) => {
        var diff = Math.abs(new Date(res.data.time_start) - new Date(res.data.time_end));
        var minutes = Math.floor((diff / 1000) / 60);

        if (res.status) {
          setState({
            ...state,
            sessionName: res.data.name,
            duration: `${minutes} minutes`,
            template_id: res.data.template_id,
            timeStart: moment(res.data.time_start).local().toDate(),
            utctimeStart: moment.utc(res.data.time_start).format(),
            zoomLink: res.data.zoom_link,
            reservation_id: res.data.id
          });
          setInitialDuration(minutes);
          setCost(getTotalCost(minutes))
          setStartDate(moment(res.data.time_start).local().toDate());
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  }
  useEffect(() => {

    setState({
      ...state,
      template_id: props.templeteid,
    });
    videoRef.current?.load();

  }, [props.animationVideo]);
  useEffect(() => {
    setState({
      ...state,
      template_id: props.templeteid,
    });

  }, [props.templeteid]);
  useEffect(() => {
    (async () => {
      
      if (props.reservation_id && props.reservation_id != '') {
        await getreservation(props.reservation_id);
      }

    })()

    return function () {
    };
  }, [props.reservation_id])
  const delete_reservations = async (reservation_id) => {
    setLoading(true);
    let data_params = {}
    data_params.user_id = auth.userDeatils.user_id;
    data_params.reservation_id = reservation_id;
    setErrormessage("")
    await AxiosApi.withToken(auth.accessToken, "reservations/delete", "post", data_params)
      .then(async (res) => {
        if (res.status) {

          toast.success(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm();
          props.setIsUpdate(!props.isUpdate);
        } else {
          setErrormessage(res.message)
          // toast.error(res.message, {
          //   position: "top-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }

        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  }

  const render_footer_for_update_schedule = () => {
    if (minUpdateEventDate < utctimeStart) {
      return (
        <>
          <div className="col-md-3 d-flex align-items-center">
            <div className="footer-text">
              <div className="col-md-12 d-flex justify-content-between" style={{lineHeight: '18px'}}>
                <span style={{ fontWeight: '700', fontSize: '16px' }}>Balance:</span> 
                <span className="text-muted" style={{ paddingLeft: '15px' }} >
                  <strong style={{ color: "#EA3F95" }}>{auth.total_coin ? auth.total_coin : ''}</strong> credits
                </span>
              </div>
              {
                cost > 0 && `${initialDuration} minutes` != duration ? (
                  <div className="col-md-12 d-flex justify-content-between"  style={{lineHeight: '18px'}}> 
                    <span style={{ fontWeight: '700', fontSize: '16px' }}> {getTotalCost(initialDuration) - cost < 0 ? 'Cost': 'Refund'}:</span> 
                    <span className="text-muted" style={{ paddingLeft: '15px' }} >
                      <strong style={{ color: "#EA3F95" }}>{Math.abs(getTotalCost(initialDuration) - cost)}</strong> credits
                    </span>
                  </div>
                ) : ""
              }
            </div>
          </div>

          <div className="col-md-3">
            <Button
              variant="contained"
              className="btn-primary float-right"
              style={{ float: 'right' }}
              disabled={disablebutton}
              onClick={(e) => submitSessionForm(e)}
            >
              Update Session
            </Button>
          </div>
        </>
      )
    } 
    return ""
  }

  const render_footer_for_create_new_schedule = () => {
    return (
      <>
        <div className="col-md-3 d-flex align-items-center">
          <div className="footer-text">
            <div className="col-md-12 d-flex justify-content-between" style={{lineHeight: '18px'}}>
              <span style={{ fontWeight: '700', fontSize: '16px' }}>Balance:</span> 
              <span className="text-muted" style={{ paddingLeft: '15px' }} >
                <strong style={{ color: "#EA3F95" }}>{auth.total_coin ? auth.total_coin : ''}</strong> credits
              </span>
            </div>
            {
              cost > 0 ? (
                <div className="col-md-12 d-flex justify-content-between"  style={{lineHeight: '18px'}}> 
                  <span style={{ fontWeight: '700', fontSize: '16px' }}>Cost:</span> 
                  <span className="text-muted" style={{ paddingLeft: '15px' }} >
                    <strong style={{ color: "#EA3F95" }}>{cost}</strong> credits
                  </span>
                </div>
              ) : ""
            }
          </div>
        </div>

        <div className="col-md-3">
          <Button
            variant="contained"
            className="btn-primary float-right"
            style={{ float: 'right' }}
            disabled={disablebutton}
            onClick={(e) => submitSessionForm(e)}
          >
            Create Session
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        size="lg"
        show={props.modal}
        onHide={() => resetForm()}
        aria-labelledby="example-modal-sizes-title-lg"
        className="create-animation-modal advanced-modal"
        animation={true}
        centered
        style={{ zIndex: "999999" }}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Schedule DeepTherapy Session
          </Modal.Title>
          <button
            type="button"
            className="modalOpenBtn btn bg-gradient-success pull-right"
            data-bs-toggle="modal"
            onClick={() => resetForm()}
          >
            <i
              style={{ fontSize: 20 }}
              className="fa fa-times"
              aria-hidden="true"
            ></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form className="session-form profile-form">
            <div id={loading ? "pageLoader" : ""} />
            <div className="session-form-block profile-form-block">
              <div className="row">
                <div className="col-md-6">
                  {props.formType === "virtualizationForm" ? (
                    <>
                      <label className="mb-2">Animation</label>

                      <div className="card" style={{ padding: '0' }}>
                        {/* <img className="card-img-top" src="..." alt="Card image cap"> */}
                        <video controls className="video_center" style={{ width: '100%', height: '300px' }}>
                          <source
                            src={`${helper.ApiBaseUrl}${props.animationData.video}`}
                          />
                        </video>
                        <div className="card-body">
                          <h5 className="card-title" style={{ fontWeight: '700' }} title={props.animationData.name}>{props.animationData.name}</h5>
                          <p className="card-text">{props.animationData.mood}</p>
                        </div>


                      </div>
                    </>
                  ) : (
                    <>
                      <label className="mb-2">Animation</label>
                      <Select
                        defaultValue={{ value: props.animationVideo, label: props.template_name, id: template_id }}
                        options={props.options}
                        onChange={props.onSelectChange}
                        className={
                          errors.template_id
                            ? "is-invalid"
                            : ""
                        }
                      />
                      {errors.template_id ? (
                        <div className="invalid-error">
                          {errors.template_id}
                        </div>
                      ) : (
                        ""
                      )}
                      {props.animationVideo ? (
                        <video controls className="video_center mt-3" ref={videoRef}>
                          <source
                            src={`${helper.ApiBaseUrl}${props.animationVideo}`}
                          />
                        </video>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-2">Session Name</label>
                      <Input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Session Name"
                        defaultValue={sessionName}
                        id="sessionName"
                        name="sessionName"
                        onChange={(e) => onChangeValue(e)}
                        invalid={errors.sessionName ? true : false}
                      />
                      <FormFeedback>{errors.sessionName}</FormFeedback>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <label className="mb-2">Time Start</label>

                      <DatePicker
                        id="timeStart"
                        name="timeStart"
                        selected={startDate}

                        onChange={(date) => onChange(date, "startDate")}
                        className={
                          errors.timeStart
                            ? "is-invalid form-control mb-2"
                            : "form-control mb-2"
                        }
                        showTimeSelect
                        dateFormat="yyyy-MM-dd hh:mm aa"
                        placeholderText="mm/dd/yyyy"
                        invalid={errors.timeStart ? true : false}
                        minDate={new Date()}
                      />
                      {errors.timeStart ? (
                        <div className="invalid-error">
                          Time Start is Required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-4">
                      <label className="mb-2">Duration</label>
                      <select className={
                        errors.duration
                          ? "is-invalid form-control mb-2"
                          : "form-control mb-2"
                      } name="duration" value={duration} onChange={(e) => onChangeValue(e)}>
                        <option value=""></option>
                        {/* <option value="15 minutes">15 minutes</option> */}
                        <option value="30 minutes">30 minutes</option>
                        {/* <option value="45 minutes">45 minutes</option> */}
                        <option value="60 minutes">1 hour</option>
                        {/* <option value="75 minutes">1.15 hours</option> */}
                        <option value="90 minutes">1.5 hours</option>
                        {/* <option value="105 minutes">1.45 hours</option> */}
                        <option value="120 minutes">2 hours</option>
                        {/* <option value="135 minutes">2.15 hours</option> */}
                        <option value="150 minutes">2.5 hours</option>
                        {/* <option value="165 minutes">2.45 hours</option> */}
                        <option value="180 minutes">3 hours</option>
                        {/* <option value="195 minutes">3.15 hours</option> */}
                        <option value="210 minutes">3.5 hours</option>
                        {/* <option value="225 minutes">3.45 hours</option> */}
                        <option value="240 minutes">4 hours</option>
                        {/* <option value="255 minutes">4.15 hours</option> */}
                        {/* <option value="270 minutes">4 hours 30 minutes</option> */}
                        {/* <option value="285 minutes">4.45 hours</option> */}
                        {/* <option value="300 minutes">5 hours</option> */}
                      </select>
                      {/* <DatePicker
                        id="timeEnd"
                        name="timeEnd"
                        selected={endDate}
                        onChange={(date) => onChange(date, "endDate")}
                        className={
                          errors.timeEnd
                            ? "is-invalid form-control mb-2"
                            : "form-control mb-2"
                        }
                        showTimeSelect
                        dateFormat="yyyy-MM-dd hh:mm aa"
                        placeholderText="mm/dd/yyyy"
                        invalid={errors.timeEnd ? true : false}
                        minDate={startDate}
                      /> */}
                      {errors.duration ? (
                        <div className="invalid-error">{errors.duration}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label className="mb-2">Timezone</label>
                      <Input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Timezone"
                        id="timezone"
                        name="timezone"
                        value={new Date().toTimeString().slice(9)}
                        readOnly={true}
                      />
                      
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <label className="mb-2">DeepTherapy Link</label>
                      <Input
                        type="text"
                        className="form-control mb-2"
                        placeholder="DeepTherapy Link"
                        id="zoomLink"
                        defaultValue={zoomLink}
                        name="zoomLink"
                        onChange={(e) => onChangeValue(e)}
                        invalid={errors.zoomLink ? true : false}
                      />
                      <FormFeedback>{errors.zoomLink}</FormFeedback>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-12">
                      {
                        reservation_id ? (
                          utctimeStart < minUpdateEventDate ? (
                            <div className="alert alert-warning  mt-2">
                              Canceling or reschedule is only possible more than 24 hours before the scheduled DeepTherapy session.
                            </div>

                          ) : ""
                        ) : ""

                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {
                        cost > auth.total_coin ? (
                          <div className="alert alert-warning mt-2">
                            Your balance is not enough to update this session.
                            <Link to={'/subscription'} style={{ color: "#00a0fe" }}>
                              <strong>Get more credits</strong>
                            </Link>
                          </div>

                        ) : ""
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {
                        errormessage != '' ? (
                          <div className="alert alert-danger alert-dismissible mt-2">
                            <span className="close" onClick={() => setErrormessage("")}>&times;</span>
                            {errormessage}
                          </div>

                        ) : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="" style={{ display: 'inline' }}>
          <div className="row">
            <div className="col-md-6">
              {
                reservation_id ? (

                  <Button
                    variant="contained"
                    className="btn-warning text-white"
                    onClick={() => delete_reservations(reservation_id)}
                  >
                    Cancel Session
                  </Button>

                ) : ""
              }
            </div>
            {
              reservation_id ? render_footer_for_update_schedule() : render_footer_for_create_new_schedule()
            }
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
