import { useState, React, useEffect } from "react";
import { Link } from "react-router-dom";
import { validateAll } from "indicative/validator";
import {  Input, FormFeedback } from "reactstrap";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import PageTitle from "../../Components/PageTitle";
import helper from "../../Helper/Helper";
// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    errors: {},
  });
  const { email,  errors } = state;

  const onChangeValue = (e) => {
    let value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const submitForgotPassword = (e) => {
    e.preventDefault();
    const rules = {
      email: "required|email"
    };
    const message = {
      "email.required": "Email is Required.",
      "email.email": "Please enter valid email."
    };
    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: {},
        });

        let forgot_password = {};
        forgot_password.email = email;
        forgot_password.domain = helper.siteUrl;

        AxiosApi.withoutToken("password_reset", "post", forgot_password)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
              setState({
                ...state,
                email: '',
                errors: {},
              });
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };
  useEffect(() => {
    return function() {
      /**
       * Add cleanup code here
       */
    };
  });
  return (
    <div className="page-wrapper login-pagewrapper">
      <PageTitle title="DeepTherapy | Forgot Password" />
      <div id={loading ? "pageLoader" : ""} />
            <main id="page-main">
                <div className="login-wrapper d-flex justify-content-center align-items-center flex-column">
                    <div className="login-container">
                        <div className="row g-0">
                            <div className="login-left col-md-5">
                                <div className="login-image-col ">
                                    <span className="position-relative d-inline-block">
                                        <img src="images/login-logo.png" alt="Welcome back" />
                                          <div className="login-logo-text">
                                              DeepTherapy
                                          </div>
                                    </span>
                                </div>
                            </div>
                            <div className="login-right p-3 col-md-7  d-flex justify-content-center align-items-center flex-column">
                                <form className="login-form">
                                    <div className="login-form-block">
                                        <h1 className="login-title text-center">
                                            Forgot Password 
                                        </h1>
                                        <div className="mb-3">
                                            <Input type="email" 
                                            invalid={errors.email ? true : false}
                                             id="email" placeholder="Email"
                                             name="email"
                                             value={email}
                                             onChange={(e) => onChangeValue(e)}
                                            />
                                            <FormFeedback>{errors.email}</FormFeedback>
                                        </div>
                                        <div className="form-action text-center">
                                            <button type="button" className="btn btn-primary btn-login w-100" onClick={(e) => submitForgotPassword(e)}>Send Email</button>
                                            <div className="form-action-link mt-2">
                                                Do have an account? <Link to="/login" className="forgot-link" title="Forgot password?">Sign In?</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="login-footer text-center">
                        © {new Date().getFullYear()} DeepTherapy. All rights reserved.
                    </div>
                </div>
            </main>
        </div>
  );
}
