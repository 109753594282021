import { useState, React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validateAll } from "indicative/validator";
import {  Input, FormFeedback } from "reactstrap";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import { signIn } from "../../Action/Auth.action";
import PageTitle from "../../Components/PageTitle";
import loginImage from "../../assets/images/login-logo.png";
// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keepme, setKeepme] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: {},
  });
  const { email, password, errors,showResend } = state;

  const onChangeValue = (e) => {
    let value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const submitLoginForm = (e) => {
    e.preventDefault();
    const rules = {
      email: "required|email",
      password: "required",
    };
    const message = {
      "email.required": "Email is Required.",
      "email.email": "Please enter valid email.",
      "password.required": "Password Number is Required.",
    };
    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: {},
        });

        let login = {};
        login.email = email;
        login.password = password;
        AxiosApi.withoutToken("login", "post", login)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              dispatch(signIn(res.data));
              setLoading(false);
              navigate("/dashboard", { replace: true });
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if (!res.is_active) {
                setState({
                  ...state,
                  showResend: 1,
                });
              }
            }
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };
  const resendEmailForm = (e) => {
    e.preventDefault();
    const rules = {
      email: "required|email",
    };
    const message = {
      "email.required": "Email is Required.",
      "email.email": "Please enter valid email.",
    };
    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: {},
        });

        let login = {};
        login.email = email;
        AxiosApi.withoutToken("resend_verification_email", "post", login)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };
  useEffect(() => {
    return function () {
      /**
       * Add cleanup code here
       */
    };
  });
  return (
    <div className="page-wrapper login-pagewrapper">
      <PageTitle title="DeepTherapy | Login" />
      <div id={loading ? "pageLoader" : ""} />
      <main id="page-main">
        <div className="login-wrapper d-flex justify-content-center align-items-center flex-column">
          <div className="login-container">
            <div className="row g-0">
              <div className="login-left col-md-5">
                <div className="login-image-col ">
                  <span className="position-relative d-inline-block">
                    
                    <img src={loginImage} alt="Welcome back" />
                    <div className="login-logo-text">
                        DeepTherapy
                    </div>
                  </span>
                </div>
              </div>
              <div className="login-right p-3 col-md-7  d-flex justify-content-center align-items-center flex-column">
                <form className="login-form">
                  <div className="login-form-block">
                    <h1 className="login-title text-center">
                      Login
                    </h1>
                    <div className="mb-3">
                      <Input type="email"
                        invalid={errors.email ? true : false}
                        id="email" placeholder="Email"
                        name="email"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <FormFeedback>{errors.email}</FormFeedback>
                    </div>
                    <div className="mb-3 position-relative">
                      <Input
                        type={showPassword ? "text" : "password"} name="password"
                        invalid={errors.password ? true : false}
                        placeholder="Password"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <span className="password-show position-absolute" onClick={(e) => setShowPassword(!showPassword)}>
                        <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                      </span>
                      <FormFeedback>{errors.password}</FormFeedback>
                    </div>
                    <div className="signed-forgot-row mb-3 d-flex justify-content-center justify-content-between">
                      <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="keepsigned" checked={ keepme ? true: false} onChange={(e) => setKeepme(!keepme)} />
                          <label className="form-check-label" onClick={(e) => setKeepme(!keepme)}>Keep me signed in</label>
                      </div>
                      <div className="forgot-link-col">
                        <Link to="/forgot_password" className="forgot-link" title="Forgot password?">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    { showResend ? 
                    <div className="signed-forgot-row mb-3 d-flex justify-content-center">
                      <div className="">
                        Click <Link to="#" className="" onClick={(e) => resendEmailForm(e)}>
                           here
                        </Link> to resend email.
                      </div>
                    </div>
                    : ""}
                    <div className="form-action text-center">
                      <button type="button" className="btn btn-primary btn-login w-100" onClick={(e) => submitLoginForm(e)}>Login</button>
                      <div className="form-action-link mt-2">
                        Don’t have an account? <Link to="/signup" className="register-link" title="Register">Sign Up</Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
           
          </div>
          <div className="login-footer text-center">
            © {new Date().getFullYear()} DeepTherapy. All rights reserved.
          </div>
        </div>
      </main>
    </div>

  );
}
