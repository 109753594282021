import React from 'react'
import { Routes } from "react-router-dom";

export default function MainRoutes() {
  
  return (
    <>
      <Routes>
        {/* <Route exact path="/" element={<Frontendlayout />}> */}
          {/* <Route path="login" element={<Login />}></Route>
          <Route path="signup" element={<Signup />}></Route> */}
        {/* </Route> */}
      </Routes>

    </>
  )
}
