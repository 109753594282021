import { React, useState, useEffect, useRef } from "react";
import { DropdownButton, Nav, Navbar, Dropdown } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import Info from "../../assets/images/info-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AddSessionForm from "./AddSessionForm";
import AxiosApi from "../../Helper/axios.api";
import moment from "moment";
import calunderpen from '../../assets/images/calunder-pen.png';
import videocall_icon from '../../assets/images/videocall_icon.png';
import NotStartedSession from "./NotStartedSession";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateTotalCoins, logout } from "../../Action/Auth.action";


export default function Sessions() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let { id } = useParams();
  let calendarComponentRef = useRef();
  let calendarComponentRefMonth = useRef();
  const [loading, setLoading] = useState(false);
  const [notStarteModal, setNotStarteModal] = useState(false);
  // const [runningModal, setRunningModal] = useState(false);
  // const [completedModal, setCompletedModal] = useState(false);

  // const [calendarValue, onCalendarChange] = useState(new Date());
  // const [dayStart, setDayStart] = useState("");
  // const [dayEnd, setDayEnd] = useState("");

  const [modal, setModal] = useState(false);
  const [reservation_id, setReservation_id] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [templeteid, settempleteid] = useState("");
  const [template_name, setTemplate_name] = useState("");
  const [timezone, setTimezone] = useState("");

  const [animationData, setAnimationData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [upcomingEventData, setUpcomingEventData] = useState([]);

  const [animationVideo, setAnimationVideo] = useState("");

  const [selectedEventStartDate, setSelectedEventStartDate] = useState("");
  const [minUpdateEventDate, setMinUpdateEventDate] = useState(moment.utc(new Date()).add(1, 'd').format());
  const [selectedEventEndDate, setSelectedEventEndDate] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");
  // const [selectedEventZoomLink, setSelectedEventZoomLink] = useState("");
  const [totalCoins, setTotalCoins] = useState('');
  const optionsDefault = animationData.map((animation) => ({
    value: animation.video,
    label: animation.name,
    id: animation.id,
  }));

  const [params, setParams] = useState({
    user_id: auth.userDeatils.user_id,
    search_value: "",
    field_name: "created_at",
    order_value: "desc",
    current_time: new Date(),
    page: 1,
    per_page: 100,
  });

  useEffect(() => {
    (async () => {
      await getAnimationData();
      await getSessionData();
      await getUpcommingSessionData();
      let date_current = moment.utc(new Date()).add(1, 'd').format()

      setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)

    })();
  }, [isUpdate]);
  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        await getUpcommingSessionData();
      })()
    }, 10000);
    return () => clearInterval(interval);

  }, [isUpdate]);
  useEffect(() => {
    setSelectedEventId("")
    if (id && id != '') {
      setSelectedEventId(id)

      setNotStarteModal(true);
    }

  }, [id])
  useEffect(() => {
    setTotalCoins(auth.total_coin)
  }, [auth.total_coin]);
  const openModel = () => {
    setModal(true);
  };

  const getAnimationData = async (e) => {
    await AxiosApi.withToken(auth.accessToken, "templates", "post", params)
      .then(async (res) => {
        if (res.data.total_coin) {
          setTotalCoins(res.data.total_coin);
        }
        setAnimationData(res.data.template);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  const getSessionData = async (e) => {
    const current = new Date();
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-01 00:00:00`;
    // setDayStart(date);
    var lastDayOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0);
    const lastdate = `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate()} 23:59:59`;
    // setDayEnd(lastdate);
    let data_params = {}
    data_params.user_id = auth.userDeatils.user_id;
    data_params.time_start = date;
    data_params.time_end = lastdate;
    data_params.search_value = "";
    await AxiosApi.withToken(auth.accessToken, "reservations", "post", data_params)
      .then(async (res) => {
        if (res.status) {
          if (res.data.length > 0) {
            let final_data = [];
            Promise.all(
              res.data.map(async (result, index) => {
                let result_data = {};
                if (result.time_start && result.time_end) {
                  result_data.id = result.id ? result.id : '';
                  result_data.title = result.name ? result.name : '';
                  result_data.start = moment(result.time_start).local().format('YYYY-MM-DD HH:mm:ss');
                  result_data.end = moment(result.time_end).local().format('YYYY-MM-DD HH:mm:ss');
                  result_data.template_id = result.template_id ? result.template_id : '';
                  result_data.template_name = result.template_name ? result.template_name : '';
                  result_data.template_url = result.template_url ? result.template_url : '';
                  result_data.zoom_link = result.zoom_link ? result.zoom_link : '';
                  final_data.push(result_data);
                }

              })
            )
            // console.log(final_data)
            setEventData(final_data);
          }

        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (!res.login) {
            dispatch(logout());
            navigate("/login", { replace: true });
          }
        }

      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  const getUpcommingSessionData = async (e) => {
    // const current = new Date();
    // let utc_time = current.getUTCDate();
    // const date = `${current.getUTCFullYear()}-${current.getUTCMonth() + 1}-${current.getUTCDate()} ${current.getUTCHours()}:${current.getUTCMinutes()}:00`;
    // setDayStart(date);
    let data_params = {}
    data_params.user_id = auth.userDeatils.user_id;
    data_params.time_start = new Date();
    data_params.limit = 5;
    await AxiosApi.withToken(auth.accessToken, "reservations/upcoming", "post", data_params)
      .then(async (res) => {
        if (res.status) {
          let final_data = [];
          if (res.data.length > 0) {

            Promise.all(
              res.data.map(async (result) => {
                let result_data = {};
                result_data.id = result.id;
                result_data.title = result.name;
                result_data.start = result.time_start;
                result_data.end = result.time_end;
                result_data.template_id = result.template_id;
                result_data.template_url = result.template_url;
                result_data.template_name = result.template_name;
                final_data.push(result_data);
              })
            )
            // console.log(final_data)

          }
          setUpcomingEventData(final_data);
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };

  const onSelectChange = (selectValue) => {
    if (selectValue) {
      let animationVideo = selectValue.value;

      setAnimationVideo(animationVideo);
      settempleteid(selectValue.id);
    } else {
      setAnimationVideo("");
      settempleteid("");
    }


  };

  const delete_reservations = async (reservation_id) => {
    setLoading(true);
    let data_params = {}
    data_params.user_id = auth.userDeatils.user_id;
    data_params.reservation_id = reservation_id;

    await AxiosApi.withToken(auth.accessToken, "reservations/delete", "post", data_params)
      .then(async (res) => {
        if (res.status) {
          setIsUpdate(!isUpdate)
          toast.success(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  }
  const update_reservations = async (reservation) => {

    setModal(true)

  }

  const show_modal = async (event) => {
    if (isCurrentlyActive(event)) {

      // window.open("/sessions/" + event.id, event.id, 'width=700,height=400');
      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

      const w = 700;
      const h = 400;

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft
      const top = (height - h) / 2 / systemZoom + dualScreenTop

      window.open("/dashboard/" + event.id, event.id,
        `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
      );

    } else if (!isPastEvent(event)) {
      const selectedEvent = upcomingEventData.find((x) => x.id === event.id);
      if (selectedEvent) {
        setAnimationVideo(selectedEvent.template_url)
        settempleteid(selectedEvent.template_id)
        setReservation_id(selectedEvent.id)
        setTemplate_name(selectedEvent.template_name)
        update_reservations(selectedEvent)
      }
    }
  }
  function diffYMDHMS(date1, date2, fortime) {
    var string_data = "";
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    if (years > 0) {
      if (fortime) {
        return date1.local().format('MMM DD,YYYY h:mm A')
      } else {
        return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
      }

    }
    if (months > 0) {
      if (fortime) {
        return date1.local().format('MMM DD,YYYY h:mm A')
      } else {
        return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
      }
      // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
    }

    if (days > 0) {
      if (days == 1) {
        if (fortime) {
          return "<b>Tomorrow</b> at <b>" + date1.local().format('h:mm A') + "</b>";
        } else {
          return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
        }
        // return "<b>Tomorrow</b> at <b>" + date1.local().format('h:mm A') + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
      } else {
        if (fortime) {
          return date1.local().format('MMM DD,YYYY h:mm A');
        } else {
          return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
        }
        // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
      }

    }

    if (hours > 0) {
      string_data += " " + hours + "h";
    }
    if (minutes > 0) {
      string_data += " " + minutes + "m";
    }
    if (seconds > 0) {
      string_data += " " + seconds + "s";
    }
    if (fortime) {
      return "<b>Start</b> in <b>" + string_data + "</b>";
    } else {
      return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
    }
    // return "<b>Start</b> in <b>" + string_data + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
  }
  const get_session_schedule = (session_data, fortime) => {

    var date2 = new Date();
    let current_time = moment(date2).format("yyyy-MM-DD HH:mm");
    let start_time = moment(session_data.start).format("yyyy-MM-DD HH:mm");
    let result = moment(current_time).isBefore(start_time);  // true
    if (result) {
      var now = moment(new Date());
      var end = moment(session_data.start);
      let diff = diffYMDHMS(end, now, fortime);
      return diff;
    } else {
      if (fortime) {
        return "<b>Session is in Progress..</b>";
      } else {
        return '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
      }
      // return '<b>Session is in Progress..</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
    }
  }
  const isCurrentlyActive = (event_data) => {
    var new_data = new Date();
    let current_time = moment(new_data).format("yyyy-MM-DD HH:mm");
    let start_time = moment(event_data.time_start || event_data.start).format("yyyy-MM-DD HH:mm");
    let end_time = moment(event_data.time_end || event_data.end).format("yyyy-MM-DD HH:mm");
    return moment(current_time).isAfter(start_time) && moment(current_time).isBefore(end_time)
  }

  const isPastEvent = (event_data) => {
    var new_data = new Date();
    let current_time = moment(new_data).format("yyyy-MM-DD HH:mm");
    let end_time = moment(event_data.time_end).format("yyyy-MM-DD HH:mm");
    return moment(current_time).isAfter(end_time)
  }

  const gotoPast = (selected_date) => {
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(selected_date); // call a method on the Calendar object
  };
  const gotoPastMonth = (selected_date) => {
    let calendarApi = calendarComponentRefMonth.current.getApi();
    calendarApi.gotoDate(selected_date); // call a method on the Calendar object
  };
  return (
    <>
      <PageTitle title="DeepTherapy | Sessions" />
      <div className="Sessions">
        <div className="card w-100">
          <div id={loading ? "pageLoader" : ""} />
          <div className="card-header bg-white d-flex align-items-center justify-content-between">
            <div className="card-header-left">
              <div className="card-header-top d-flex align-items-center mb-2">
                <div className="card-title">My Sessions</div>
                <div className="credit-remaining-text px-3">
                  <strong>{totalCoins ? totalCoins : ""}</strong> <span className="text-gray">credits remaining</span>
                </div>
                <div className="credit-info-col tooltip">
                  <span
                    className="credit-info-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={totalCoins ? totalCoins + " credits remaining" : ''}
                  >
                    <img src={Info} alt="Info" />
                    <span className="tooltiptext">
                      Animation = 1 credit per face<br />
                      Zoom-session = 20 credits per 30 mins
                    </span>
                  </span>
                </div>
                <div className="more-credits-col ps-3">
                  <Link to={'/subscription'} >Get more credits</Link>
                </div>
              </div>
              <div className="card-header-bottom d-flex align-items-center">
                {/* <div className="search-col pe-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    type="text"
                    name="search_value"
                    id="search_value"
                    className="form-control"
                    placeholder="Search sessions..."
                  />
                </div>
              </div> */}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-createanimation"
              data-bs-toggle="modal"
              onClick={() => openModel()}
            >
              + Create New
            </button>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-3 dayGridMonth">
                {/* <Calendar
                onChange={onCalendarChange}
                value={calendarValue}
                className="calender"
              /> */}
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={
                    {
                      left: 'prev', // hide prev,next today in mobile view
                      center: 'title',
                      right: 'next' // hide dayGridMonth on small view
                      // center: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                  displayEventTime={false}
                  allDaySlot={false}
                  events={eventData}
                  //events={[]}
                  eventColor={"#1c5db4"}
                  nowIndicator
                  dateClick={(e) => gotoPast(e.dateStr)}
                  //dateClick={(e) => delete_reservations('4fc44bf6-14b1-4e2c-ad7d-fbf6ef674fd0')}

                  eventClick={(e) => {
                    show_modal(e.event)
                    // setSelectedEventStartDate(e.event.start)
                    // setSelectedEventEndDate(e.event.end)
                    // setSelectedEventId(e.event.id)

                    // setNotStarteModal(true);
                  }}
                  // eventClick={(e) => console.log(e.event.id)}
                  //contentHeight={"auto"}
                  // showNonCurrentDates = {false}
                  fixedWeekCount={false}
                  height={"auto"}
                  // handleWindowResize={true}
                  themeSystem={"bootstrap4"}
                  ref={calendarComponentRefMonth}
                />

                <div className="upcoming mt-5">
                  <h4 className="sidebar-label-title">Upcoming Events</h4>
                  <div className="upcoming-session-list allow-scroll">
                    {
                      upcomingEventData.length > 0 ?
                        upcomingEventData.map((event_data, index) => (
                          <div className={`mt-2 d-flex session-item ${isCurrentlyActive(event_data) ? 'session-item-active' : ''}`} key={index}
                            onClick={(e) => isCurrentlyActive(event_data) && show_modal(event_data)}
                          >
                            <div className="session-item-icon text-center">
                              {
                                minUpdateEventDate > event_data.start ? (
                                  <img src={videocall_icon} className="session-item-icon-image" alt="edit" style={{
                                    "width": "28px",
                                    "height": "auto",
                                    "paddingTop": "6px"
                                  }} />
                                ) : (
                                  <img src={calunderpen} className="session-item-icon-image" alt="edit" />
                                )
                              }
                            </div>
                            <div className="session-item-details d-flex flex-column justify-content-center">
                              <p className="session-item-details-title">{event_data.title}</p>
                              <p className="session-item-details-descriptions">
                                <span style={{ color: "#000", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: get_session_schedule(event_data, true) }}>

                                </span>
                              </p>
                            </div>
                            <div className="session-item-actions">
                              {!isCurrentlyActive(event_data) ?
                                <DropdownButton
                                  variant={"bg-transparent"}
                                  className="drp-navbar-name"
                                  align="end"
                                  title={
                                    <>
                                      <span style={{ fontWeight: "700" }}>...</span>
                                    </>
                                  }
                                >
                                  {
                                    minUpdateEventDate < event_data.start ?
                                      (
                                        <Dropdown.Item onClick={() => {
                                          setAnimationVideo(event_data.template_url)
                                          settempleteid(event_data.template_id)
                                          setReservation_id(event_data.id)
                                          setTemplate_name(event_data.template_name)
                                          update_reservations(event_data)
                                        }}>
                                          Update
                                        </Dropdown.Item>
                                      ) : ""
                                  }


                                  <Dropdown.Item onClick={() => delete_reservations(event_data.id)}>
                                    Delete
                                  </Dropdown.Item>
                                </DropdownButton>
                                : ""}
                            </div>
                          </div>
                        ))

                        : (
                          <p className="small textmuted">No upcoming events.</p>
                        )
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-9 dayGridWeek">

                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="timeGridWeek"
                  datesSet={(dateInfo) => {
                    gotoPastMonth(moment(dateInfo.end).subtract(1, 'd').format('YYYY-MM-DD'))
                    console.log(dateInfo) //start of the range the calendar date
                    // console.log(dateInfo.end) //end of the range the calendar date
                  }}
                  ref={calendarComponentRef}
                  headerToolbar={
                    {
                      left: 'prev,next', // hide prev,next today in mobile view
                      center: 'title',
                      right: 'timeZone' // hide dayGridMonth on small view
                      // center: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                  customButtons={{
                    timeZone: {
                      //text: Intl.DateTimeFormat().resolvedOptions().timeZone,
                      text: new Date().toTimeString().slice(9),
                      click: () => console.log('new event'),
                    },
                  }}
                  allDaySlot={false}
                  events={eventData}
                  eventColor={"#1c5db4"}
                  nowIndicator
                  slotDuration={"00:30"}
                  timeFormat={'H(:mm)'}
                  slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: true, hour12: true }}

                  // defaultDate={"2012-05-25"} 
                  //eventClick={(e) => console.log(e.event.id)}
                  eventClick={(e) => {
                    show_modal(e.event)
                    // setSelectedEventStartDate(e.event.start)
                    // setSelectedEventEndDate(e.event.end)
                    // setSelectedEventId(e.event.id)

                    // setNotStarteModal(true);
                  }}
                  // contentHeight={"auto"}
                  handleWindowResize={true}
                  themeSystem={"bootstrap4"}

                />
              </div>
            </div>
          </div>
        </div>
        <AddSessionForm
          modal={modal}
          setModal={setModal}
          animationData={animationData}
          options={optionsDefault}
          onSelectChange={onSelectChange}
          formType={"sessionsForm"}
          animationVideo={animationVideo}
          setAnimationVideo={setAnimationVideo}
          templeteid={templeteid}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          reservation_id={reservation_id}
          setReservation_id={setReservation_id}
          template_name={template_name}
          setTemplate_name={setTemplate_name}
          settempleteid={settempleteid}
        />
        <NotStartedSession
          modal={notStarteModal}
          setModal={setNotStarteModal}
          start_date={selectedEventStartDate}
          end_date={selectedEventEndDate}
          id={selectedEventId}


        />
      </div>
    </>
  );
}