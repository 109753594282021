import AxiosApi from "../Helper/axios.api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SubscriptionItem = (props) => {
  const auth = useSelector((user) => user.auth);
  const handleSubmit = async (e, price_id) => {
    const payload = {
      price_id: price_id,
      user_id: auth.userDeatils.user_id,
      coins: props.metadata.credits,
      product_id: props.id,
    };
    console.log(payload);
    e.preventDefault();
    await AxiosApi.withToken(auth.accessToken, "products/buy", "post", payload)
      .then(async (res) => {
        if (res.status) {
          localStorage.setItem('stripeUrl', res.data.url);
          window.open(res.data.url)
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((errors_res) => {
        console.log(errors_res);
      });
  };
  return (
    <div className="col-md-4">
      <div
        className={
          props.number === 1
            ? "card pricing-card pricing-card-highlighted  pricing-plan-enterprise"
            : "card pricing-card pricing-plan-enterprise"
        }
      >
        <div className="card-body">
          <i className="fa fa-paper-plane pricing-plan-icon"></i>
          <p className="pricing-plan-title">{props.name}</p>
          <h3 className="pricing-plan-cost ml-auto">
            € {Math.floor(props.price * 0.01)}
            <span style={{ fontSize: "0.45em" }}>
              .{Math.floor(((props.price * 0.01) % 1) * 100)}
            </span>
          </h3>
          <ul className="pricing-plan-features">
            <li>
              <strong>{props.metadata.credits} Credits</strong>
            </li>
            {props.description}
          </ul>
          <form
            onSubmit={(e) => handleSubmit(e, props.default_price)}
            method="POST"
          >
            <button className="btn pricing-plan-purchase-btn">
              {props.name}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionItem;
