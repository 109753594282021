import React from 'react'
import { useSelector } from "react-redux";
import LoginRoutes from './LoginRoutes';
import AdminPrivateRoutes from './AdminPrivateRoutes';
import MainRoutes from './MainRoutes';
import UserPrivateRoutes from './UserPrivateRoutes';

export default function Routes() {
    const auth = useSelector((state) => state.auth);
    return (
        <div>
            {
                auth.accessToken && auth.role === 'Admin' 
                ? (
                    <AdminPrivateRoutes />
                )
                : ""
            }
            {
                auth.accessToken && auth.role === 'User' 
                ? (
                    <UserPrivateRoutes />
                )
                : ''
            }
            {
                !auth.accessToken || !auth.role ? (
                    <LoginRoutes />
                ) : ''
            }
            
             <MainRoutes />
        </div>
    )
}
