import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Userlayout from "../Components/User/Layout/Userlayout";
import Dashboard from "../Pages/Dashboard";
import Profile from "../Pages/Profile";
import Sessions from "../Pages/Session/Sessions";
import SubscriptionPlan from "../Pages/SubscriptionPlan";
import Payment from "../Pages/Payment";
import History from "../Pages/History";
import Completed from "../Pages/Completed";
import SuccessPayment from "../Pages/SuccessPayment";
import UsersTrainings from "../Pages/UsersTrainings";

export default function UserPrivateRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Userlayout />}>
          <Route index path="" element={<Dashboard />}></Route>
          <Route exact path="dashboard" element={<Dashboard />}></Route>
          <Route exact path="dashboard/:id" element={<Dashboard />}></Route>
          <Route exact path="trainings" element={<UsersTrainings />}></Route>
          <Route exact path="profile" element={<Profile />}></Route>
          <Route exact path="sessions" element={<Sessions />}></Route>
          <Route exact path="sessions/:id" element={<Sessions />}></Route>
          <Route
            exact
            path="subscription"
            element={<SubscriptionPlan />}
          ></Route>
          <Route exact path="payment/:id" element={<Payment />}></Route>
          <Route exact path="history" element={<History />}></Route>
          <Route exact path="completed" element={<Completed />}></Route>
          <Route
            exact
            path="payment/success"
            element={<SuccessPayment />}
          ></Route>
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </>
  );
}
