import {
    UPDATE_USERDETAILS,
    LOGOUT,
    USERLOGIN,
    UPDATE_TOTAL_COIN
} from "../Type/auth.type";
import authInitialState from "../Initialstate/auth.initialstate";


const authuserReducer = (state = authInitialState, action) => {

    let response = action.response;
    switch (action.type) {
        case USERLOGIN:
            return {
                ...state,
                accessToken: response.token,
                userDeatils: response,
                role: "User",
                name: response.name,
                default_zoom_link: response.default_zoom_link,
                total_coin: response.total_coin
            }
        case LOGOUT:
            return {
                ...state,
                accessToken: null,
                userDeatils: null,
                role: null,
                name: "",
                default_zoom_link: "",
                total_coin: ""
            }
        case UPDATE_USERDETAILS:

            return {
                ...state,
                name: response.name,
                default_zoom_link: response.default_zoom_link
            }
        case UPDATE_TOTAL_COIN:

            return {
                ...state,
                total_coin : response.total_coin
            }

        default:
            return state;
    }
};

export default authuserReducer;
