import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import AxiosApi from '../Helper/axios.api';
import { useSelector } from "react-redux";

function AditionalInformations({ recievedDemo, setReceivedDemo }) {
    const [show, setShow] = useState(false);
    const auth = useSelector((state) => state.auth);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSendInfo = async (e) => {
        e.preventDefault();

        const formData = {
            background: e.target.background.value,
            website: e.target.website.value,
            useCase: e.target.useCase.value,
            exposureTherapy: e.target.exposureTherapy.value,
        };

        const response = await AxiosApi.withToken(
            auth.accessToken,
            'trainings',
            'post',
            {
                user_id: auth.userDeatils.user_id,
                ...formData
            }
        );

        setReceivedDemo(p => {
            return {
                ...p,
                requested: true
            }
        });

        handleClose()

        console.log('====================================');
        console.log(response);
        console.log('====================================');
        toast.success(response.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
    <>
        <button
            type="button"
            className="btn btn-warning btn-createanimation"
            onClick={handleShow}
            disabled={recievedDemo.requested}
            >
            Request free demo: intake and instructions
        </button>

        <Modal 
            show={show}         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered    
            className="my-custom-modal"
            onHide={handleClose}
            style={{ zIndex: "999999" }}
        >
        <Modal.Header closeButton>
            <Modal.Title>
                Questionnaire
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSendInfo}>
            <Form.Group>
                <Form.Label>
                    Please provide your professional background.
                </Form.Label>
                <Form.Control type="text" name="background" placeholder="Enter your background" />
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    If applicable, supply your website.
                </Form.Label>
                <Form.Control type="text" name="website" placeholder="Enter website URL" />
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    Specify the particular use case you have in mind for applying our technology.
                </Form.Label>
                <Form.Control as="textarea" name="useCase" rows={3} />
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    Share your familiarity and experience with (exposure) therapy.
                </Form.Label>
                <Form.Control as="textarea" name="exposureTherapy" rows={3} />
            </Form.Group>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Modal.Footer>
            </Form>
        </Modal.Body>
        </Modal>
    </>
    );

}

export default AditionalInformations;
