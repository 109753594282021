import { React, useEffect, useState, useRef } from "react";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Label, Input, FormFeedback } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import AxiosApi from "../Helper/axios.api";
import { toast } from "react-toastify";
import PageTitle from "../Components/PageTitle";
import helper from "../Helper/Helper";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { validateAll } from "indicative/validator";
import AddSessionForm from "./Session/AddSessionForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotStartedSession from "./Session/NotStartedSession";
import { updateTotalCoins, logout } from "../Action/Auth.action";
import HoverVideoPlayer from "react-hover-video-player";
import UpdateAnimation from "./UpdateAnimation";
import { Select } from "react-select-virtualized";

import Info from "../assets/images/info-icon.svg";
import Arrow from "../assets/images/select-arrow.svg";
import asc from "../assets/images/sort_asc.png";
import desc from "../assets/images/sort_desc.png";
import Uplaod_icon from "../assets/images/upload-icon.svg";
import Uplaod_sample from "../assets/images/upload-sample.svg";
import no_animation_icon from "../assets/images/no_animation.png";
import play_icon from "../assets/images/play.png";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import AditionalInformations from "../Components/AditionalInformations";

export default function Dashboard() {
  const dispatch = useDispatch();
  const image_box_ref = useRef(null);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [reservation_id, setReservation_id] = useState("");
  const [step, setStep] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [errormessage, setErrormessage] = useState("");
  const [startPage, setStartPage] = useState(1);
  const [imageBox, setImageBox] = useState(null);
  const [imageBoxHeight, setImageBoxHeight] = useState("");
  const [imageBoxWidth, setImageBoxWidth] = useState("");
  const [facekey, setFacekey] = useState(0);
  const [mood, setMood] = useState("agreeable");
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [recievedDemo, setReceivedDemo] = useState(true);

  const [createAnimationModalShow, setCreateAnimationModalShow] =
    useState(false);
  const [editAnimationModalShow, setEditAnimationModalShow] = useState(false);
  const [createReservationModalShow, setCreateReservationModalShow] =
    useState(false);
  const [notStarteModal, setNotStarteModal] = useState(false);

  const [templeteid, settempleteid] = useState("");
  const [animationData, setAnimationData] = useState([]);
  const [totalCoins, setTotalCoins] = useState("");
  const [checked, setChecked] = useState(false);
  const [animation, setAnimation] = useState({
    id: "",
    name: "",
    img: "",
    thumb: "",
    video: "",
  });
  const [animations, setAnimations] = useState([]);
  const [emotions, setEmotions] = useState([]);
  const [emotionsOptions, setEmotionsOptions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  let { id } = useParams();
  const [params, setParams] = useState({
    user_id: auth.userDeatils.user_id,
    search_value: "",
    field_name: "session",
    order_value: "asc",
    current_time: new Date(),
    page: 1,
    per_page: 10,
  });

  const [state, setState] = useState({
    template_id: "",
    image_name: "",
    img_url: "",
    thumb: "",
    video: "",
    user_id: auth.userDeatils.user_id,
    face_postions: [],
    image_size_face: [],
    face_thumbnails: [],
    title: "",
    errors: {},
  });
  const {
    template_id,
    face_postions,
    title,
    image_size_face,
    face_thumbnails,
    image_name,
    img_url,
    thumb,
    video,
    user_id,
    errors,
  } = state;
  const timerId = setInterval(async () => {
    // await getAnimations();
  }, 10000);
  useEffect(() => {
    (async () => {
      await getAnimations();
      await getEmotions();
    })();

    return () => {};
  }, [isUpdate, params.page, params.per_page]);
  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        await getAnimations();
      })();
    }, 10000);
    return () => clearInterval(interval);
  }, [isUpdate, params.page, params.per_page]);
  clearInterval(timerId);
  useEffect(() => {
    setSelectedEventId("");
    if (id && id != "") {
      setSelectedEventId(id);

      setNotStarteModal(true);
    }
  }, [id]);
  useEffect(() => {
    if (imageBox) {
      setImageBoxWidth(imageBox.clientWidth);
      setImageBoxHeight(imageBox.clientHeight);
    }
  }, [imageBox]);
  useEffect(() => {
    setTotalCoins(auth.total_coin);
  }, [auth.total_coin]);

  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [mood]);

  useEffect(() => {
    checkUserTrainingsStatus();
  },[]);
  const getListSize = async () => {
    if (
      image_box_ref &&
      (image_box_ref.current !== "null" || image_box_ref.current !== null)
    ) {
      const newWidth = await image_box_ref.current.clientWidth;
      setImageBoxWidth(newWidth);

      const newHeight = await image_box_ref.current.clientHeight;
      setImageBoxHeight(newHeight);
    }
  };
  const changefile = async (e) => {
    setErrormessage("");
    const file = e.target.files[0];

    const allowed_type = ["image/jpeg", "image/jpg", "image/png"];
    if (allowed_type.indexOf(file.type) === -1) {
      setErrormessage("File type not suppoted");
      return false;
    }
    if (file.size > 10000000) {
      setErrormessage("File Size limit 10MB");
      return false;
    }

    setLoading(true);
    let data = new FormData();
    data.append("file", file);
    data.append("user_id", user_id);
    data.append("template_id", template_id);
    AxiosApi.withToken(auth.accessToken, "templates/create", "post", data)
      .then((res) => {
        if (res.status) {
          const min = 1;
          const max = 100;
          let random = min + Math.random() * (max - min);
          setState((state) => ({
            ...state,
            template_id: res.data.id,
            image_name: res.data.name,
            title: res.data.name,
            img_url: helper.ApiBaseUrl + res.data.img + "?" + random,
            thumb: res.data.thumb,
            video: res.data.video,
          }));
        } else {
          setErrormessage(res.message);
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };

  const checkUserTrainingsStatus = async () => {
    const response = await AxiosApi.withToken(
      auth.accessToken,
      "trainings/check_user",
      "get"
    );
    console.log(response)
    setReceivedDemo(response);
  };

  const get_faces = () => {
    setErrormessage("");
    setLoading(true);
    let data = {};
    data.template_id = template_id;
    data.user_id = auth.userDeatils.user_id;
    AxiosApi.withToken(auth.accessToken, "templates/find_faces", "post", data)
      .then((res) => {
        if (res.status) {
          if (!res.data.faces.length) {
            setErrormessage("Face not found.");
          } else if (res.data.size[0] < 200 || res.data.size[1] < 200) {
            setErrormessage("Please Upload min 200px image size.");
          } else {
            setState((state) => ({
              ...state,
              face_postions: res.data.faces,
              image_size_face: res.data.size,
              face_thumbnails: res.data.thumbnails,
            }));
            setStep(1);
          }
        } else {
          setErrormessage(res.message);
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  const templete_preview = () => {
    setErrormessage("");
    setIsGenerating(true);
    // let data = {};
    let data = new FormData();
    data.append("user_id", auth.userDeatils.user_id);
    data.append("template_id", template_id);
    data.append("face_box", face_postions[facekey]);
    data.append("mood", mood);
    AxiosApi.withToken(
      auth.accessToken,
      "templates/create_preview",
      "post",
      data
    )
      .then((res) => {
        if (res.status) {
          setState((state) => ({
            ...state,
            thumb: res.data.thumb,
            video: helper.ApiBaseUrl + res.data.video,
          }));
          settempleteid(res.data.id);
          setEditAnimationModalShow(true);
          setState((state) => ({
            ...state,
            template_id: "",
            image_name: "",
            img_url: "",
            thumb: "",
            video: "",
            user_id: auth.userDeatils.user_id,
            face_postions: [],
            image_size_face: [],
            face_thumbnails: [],
            title: "",
          }));
          setStep(0);
          setIsGenerating(false);
          setCreateAnimationModalShow(false);
          // setStep(3);
        } else {
          setErrormessage(res.message);
        }
        setIsGenerating(false);
      })
      .catch((errors_res) => {
        setIsGenerating(false);
      });
  };
  const save_visulation = (e) => {
    setErrormessage("");
    e.preventDefault();
    const rules = {
      title: "required",
    };
    const message = {
      "title.required": "Name is Required.",
    };
    validateAll(state, rules, message)
      .then(async () => {
        setLoading(true);
        let data = {};
        data.template_id = template_id;
        data.user_id = auth.userDeatils.user_id;
        data.name = title;
        AxiosApi.withToken(auth.accessToken, "templates/accept", "post", data)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setState((state) => ({
                ...state,
                template_id: "",
                image_name: "",
                img_url: "",
                thumb: "",
                video: "",
                face_postions: [],
                image_size_face: [],
                face_thumbnails: [],
                title: "",
              }));
              dispatch(updateTotalCoins(res.data));
              setStep(0);
              setCreateAnimationModalShow(false);
              setIsUpdate(!isUpdate);
            } else {
              setErrormessage(res.message);
            }
            setLoading(false);
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };
  const getEmotions = async (e) => {
    await AxiosApi.withToken(auth.accessToken, "emotions", "get", {})
      .then(async (res) => {
        if (res.status) {
          setEmotionsOptions(
            res.data.map((item, index) => {
              return {
                value: item.name,
                label: item.name,
                id: item.name,
              };
            })
          );
          setEmotions(res.data);
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  const getAnimations = async (e) => {
    await AxiosApi.withToken(auth.accessToken, "templates", "post", params)
      .then(async (res) => {
        if (res.status) {
          setAnimations([...res.data.live_templates, ...res.data.template]);
          setEndPage(res.data.pagination.last_pages);
          if (res.data.total_coin) {
            setTotalCoins(res.data.total_coin);
          }
          dispatch(updateTotalCoins(res.data));
          setTotalRows(res.data.pagination.total);
          getPager(
            res.data.pagination.total,
            res.data.pagination.current_page,
            params.per_page,
            res.data.pagination.last_pages
          );
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (!res.login) {
            dispatch(logout());
            navigate("/login", { replace: true });
          }
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  function getPager(totalItems, currentPage, pageSize, last_pages) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(last_pages + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    setStartIndex(startIndex);
    setEndIndex(endIndex);
    setPages(pages);
    setTotalPages(totalPages);
  }

  const onSelectChange = (selectValue) => {
    if (selectValue) {
      setMood(selectValue.value);
    } else {
    }
  };

  //This is for delete animation
  const deleteAnimation = (id) => {
    confirmAlert({
      title: "Animation",
      message: "Are you sure to do delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let data = {};
            data.user_id = auth.userDeatils.user_id;
            data.template_id = id;

            await AxiosApi.withToken(
              auth.accessToken,
              "templates/delete",
              "post",
              data
            )
              .then((res) => {
                if (res.status) {
                  //await get_animation();
                  toast.success(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });

                  setIsUpdate(!isUpdate);
                } else {
                  toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                setLoading(false);
              })
              .catch((errors_res) => {
                setLoading(false);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getAnimation = async (id) => {
    let data = {};
    await AxiosApi.withToken(
      auth.accessToken,
      "templates/" + id + "/details",
      "get",
      data
    )
      .then((res) => {
        if (res.status) {
          setAnimation(res.data);
          setEditAnimationModalShow(true);
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };

  const updateAnimation = async () => {
    let data = {};
    data.user_id = auth.userDeatils.user_id;
    data.template_id = animation.id;
    data.name = animation.name;

    await AxiosApi.withToken(auth.accessToken, "templates/edit", "post", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setEditAnimationModalShow(false);
          setIsUpdate(!isUpdate);
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  const onChangeValueState = (e) => {
    let value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const onChangeValue = (e) => {
    let value = e.target.value;
    setAnimation({
      ...animation,
      [e.target.name]: value,
    });
  };
  const setPagenumber = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  //THis is for search
  const onParamChange = (e) => {
    let value = e.target.value;
    setParams({
      ...params,
      [e.target.name]: value,
    });
    setIsUpdate(!isUpdate);
  };
  const requestSort = (key) => {
    let direction = "asc";
    if (params && params.field_name === key && params.order_value === "asc") {
      direction = "desc";
    }
    setParams({
      ...params,
      field_name: key,
      order_value: direction,
    });

    setIsUpdate(!isUpdate);
  };
  const getClassNamesFor = (name) => {
    if (!params) {
      return Arrow;
    }
    if (params.field_name === name) {
      if (params.order_value == "asc") {
        return asc;
      } else {
        return desc;
      }
    } else {
      return Arrow;
    }
  };
  function face_detection(index, item) {
    let o_width = image_size_face[0];
    let o_height = image_size_face[1];

    let height_ration = imageBoxHeight / o_height;
    let width_ration = imageBoxWidth / o_width;
    let ration = 0;
    if (width_ration > height_ration) {
      ration = width_ration;
    } else {
      ration = height_ration;
    }

    if (ration > 1) {
      ration = 1;
    }
    let new_left = item[0] * ration;
    let new_top = item[1] * ration;
    let new_right = item[2] * ration;
    let new_bottom = item[3] * ration;

    return (
      <div
        className={
          facekey === index ? "face_detection_active" : "face_detection"
        }
        onClick={() => setFacekey(index)}
        key={index}
        style={{
          position: "absolute",
          cursor: "pointer",
          left: new_left,
          top: new_top,
          width: new_right - new_left,
          height: new_bottom - new_top,
          backgroundImage: `url(${helper.ApiBaseUrl}${face_thumbnails[index]})`,
          backgroundSize: new_right - new_left,
        }}
      ></div>
    );
  }
  function set_face_dorder(facekey, index) {
    return (facekey = index ? "1px dotted" : "1px solid");
  }

  const showCreateReservationModal = (row) => {
    setAnimationData(row);
    settempleteid(row.id);
    setCreateReservationModalShow(true);
  };

  const showEditReservationModal = (session, animation) => {
    setAnimationData(animation);
    settempleteid(session.template_id);
    setReservation_id(session.id);
    setIsUpdate(true);
    setCreateReservationModalShow(true);
  };

  const back_to_main = () => {
    setErrormessage("");
    setState((state) => ({
      ...state,
      image_name: "",
      img_url: "",
      thumb: "",
      video: "",
      face_postions: [],
      image_size_face: [],
      face_thumbnails: [],
      title: "",
    }));
    setStep(0);
  };

  function image_load() {
    return <img key={new Date()} src={img_url} alt="Upload" />;
  }
  function diffYMDHMS(date1, date2, fortime) {
    var string_data = "";
    let years = date1.diff(date2, "year");
    date2.add(years, "years");

    let months = date1.diff(date2, "months");
    date2.add(months, "months");

    let days = date1.diff(date2, "days");
    date2.add(days, "days");

    let hours = date1.diff(date2, "hours");
    date2.add(hours, "hours");

    let minutes = date1.diff(date2, "minutes");
    date2.add(minutes, "minutes");

    let seconds = date1.diff(date2, "seconds");

    if (years > 0) {
      if (fortime) {
        return date1.local().format("MMM DD,YYYY h:mm A");
      } else {
        return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
      }
    }
    if (months > 0) {
      if (fortime) {
        return date1.local().format("MMM DD,YYYY h:mm A");
      } else {
        return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
      }
      // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
    }

    if (days > 0) {
      if (days == 1) {
        if (fortime) {
          return (
            "<b>Tomorrow</b> at <b>" + date1.local().format("h:mm A") + "</b>"
          );
        } else {
          return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
        }
        // return "<b>Tomorrow</b> at <b>" + date1.local().format('h:mm A') + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
      } else {
        if (fortime) {
          return date1.local().format("MMM DD,YYYY h:mm A");
        } else {
          return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
        }
        // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
      }
    }

    if (hours > 0) {
      string_data += " " + hours + "h";
    }
    if (minutes > 0) {
      string_data += " " + minutes + "m";
    }
    if (seconds > 0) {
      string_data += " " + seconds + "s";
    }
    if (fortime) {
      return "<b>Start</b> in <b>" + string_data + "</b>";
    } else {
      return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
    }
    // return "<b>Start</b> in <b>" + string_data + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
  }
  const get_session_schedule = (session_data, fortime) => {
    var date2 = new Date();
    let current_time = moment(date2).format("yyyy-MM-DD HH:mm");
    let start_time = moment(session_data.time_start).format("yyyy-MM-DD HH:mm");
    let result = moment(current_time).isBefore(start_time); // true
    if (result) {
      var now = moment(new Date());
      var end = moment(session_data.time_start);
      let diff = diffYMDHMS(end, now, fortime);
      return diff;
    } else {
      if (fortime) {
        return "<b>Session is in Progress..</b>";
      } else {
        return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
      }
      // return '<b>Session is in Progress..</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
    }
  };
  const show_modal = async (event_id) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const w = 700;
    const h = 400;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop

    window.open("/dashboard/" + event_id, event_id, 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );
    // navigate("/dashboard/" + event_id, { replace: true });
  };
  const update_reservations = async () => {
    setCreateReservationModalShow(true);
  };

  return (
    <>
      <PageTitle title="DeepTherapy | Virtualization" />
      <div className="virtualization">
        <div className="card w-100">
          <div id={loading ? "pageLoader" : ""} />
          <div className="card-header bg-white d-flex align-items-center justify-content-between">
            <div className="card-header-left">
              <div className="card-header-top d-flex align-items-center mb-2">
                <div className="card-title">My Animations</div>
                <div className="credit-remaining-text px-3">
                  <strong>{totalCoins ? totalCoins : ""}</strong>{" "}
                  <span className="text-gray">credits remaining</span>
                </div>
                <div className="credit-info-col tooltip">
                  <span
                    className="credit-info-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={totalCoins ? totalCoins + " credits remaining" : ""}
                  >
                    <img src={Info} alt="Info" />
                    <span className="tooltiptext">
                      Animation = 1 credit per face
                      <br />
                      DeepTherapy session = 20 credits per 30 mins
                    </span>
                  </span>
                </div>
                <div className="more-credits-col ps-3">
                  <Link to={"/subscription"}>Get more credits</Link>
                </div>
                <div className="more-credits-col ps-3">
                  <a href="/download/tutorials/Deeptherapy_tutorial.mp4">
                    Watch tutorial
                  </a>
                </div>
              </div>
              <div className="card-header-bottom d-flex align-items-center">
                {/* <div className="sortby-col">
                <select
                  className="form-select"
                  name="field_name"
                  id="field_name"
                  defaultValue={""}
                  aria-label="Default select"
                  onChange={(e) => onParamChange(e)}
                >
                  <option value="">Sort by</option>
                  <option value="created_at">Date</option>
                  <option value="id">ID</option>
                </select>
              </div> */}
              </div>
            </div>
            <div className="card-header-right">
              <div className="search-col pe-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    type="text"
                    name="search_value"
                    id="search_value"
                    className="form-control"
                    placeholder="Search animations..."
                    onChange={(e) => onParamChange(e)}
                  />
                </div>
              </div>
              {recievedDemo.status ? 
                (
                  <button
                    type="button"
                    className="btn btn-primary btn-createanimation"
                    onClick={() => setCreateAnimationModalShow(true)}
                  >
                    + Create Animation
                  </button>
                ) :               
                (
                  <AditionalInformations recievedDemo={recievedDemo} setReceivedDemo={setReceivedDemo} />
                )
              }
            </div>
          </div>
          <div className="card-body">
            <div className="animation-thead d-flex">
              <div className="animation-th animation-col d-flex align-items-center justify-content-between w-10">
                Animations
                {/* <img src={Arrow} alt="Arrow" /> */}
              </div>
              <div
                className="animation-th title-col d-flex align-items-center justify-content-between w-25"
                onClick={() => requestSort("name")}
              >
                Title
                <img src={getClassNamesFor("name")} alt="Arrow" />
              </div>
              <div
                className="animation-th date-col d-flex align-items-center justify-content-between w-15"
                onClick={() => requestSort("created_at")}
              >
                Date
                <img src={getClassNamesFor("created_at")} alt="Arrow" />
              </div>
              <div
                className="animation-th agenda-col d-flex align-items-center justify-content-between w-20"
                onClick={() => requestSort("session")}
              >
                Agenda
                <img src={getClassNamesFor("session")} alt="Arrow" />
              </div>
              <div className="animation-th status-col w-30 text-center">
                Action
              </div>
            </div>
            <div className="animation-tbody">
              {animations.length > 0 ? (
                animations.map((row, index) => (
                  <div
                    key={index}
                    className="animation-tr d-flex align-items-center"
                    style={
                      row.live && {
                        background: "#E9F1FB",
                        border: "2px solid #1C5DB4",
                        position: "relative",
                        paddingRight: 35,
                      }
                    }
                  >
                    <div className="animation-td animation-col w-10 d-flex">
                      <HoverVideoPlayer
                        className="hover-video-player"
                        style={{ width: "6rem", overflow: "hidden" }}
                        videoSrc={`${helper.ApiBaseUrl}${row.video}`}
                        restartOnPaused
                        playbackRangeStart={1.5}
                        loadingOverlay={
                          <div className="loading-overlay">
                            <div className="loading-spinner" />
                          </div>
                        }
                        pausedOverlay={
                          <img
                            src={play_icon}
                            alt="mouseover to play video"
                            style={{
                              width: "25%",
                              height: "25%",
                              position: "absolute",
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="animation-td title-col w-25">
                      <span className="animation-name h1 mb-0">
                        {row.name ? row.name : ""}
                      </span>
                      <span className="animation-reaction">
                        {row.mood ? row.mood : ""}
                      </span>
                    </div>
                    <div className="animation-td date-col w-15">
                      {row.created_at
                        ? moment(row.created_at).format("MMM D, YYYY hh:mm A")
                        : ""}
                    </div>
                    <div className="animation-td agenda-col w-20">
                      {row.session ? (
                        <>
                          <a
                            style={{ color: "#1c5db4", cursor: "pointer" }}
                            onClick={(e) => show_modal(row.session.id)}
                          >
                            <span
                              style={{ color: "#000", fontWeight: "normal" }}
                              dangerouslySetInnerHTML={{
                                __html: get_session_schedule(row.session, true),
                              }}
                            ></span>
                          </a>
                          {(() => {
                            let string_data = get_session_schedule(
                              row.session,
                              false
                            );
                            if (string_data.includes("Open")) {
                              return (
                                <a
                                  style={{
                                    color: "#1c5db4",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => show_modal(row.session.id)}
                                >
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "normal",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: string_data,
                                    }}
                                  ></span>
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  style={{
                                    color: "#1c5db4",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setAnimationData(row);
                                    settempleteid(row.id);
                                    setReservation_id(row.session.id);
                                    // setTemplate_name(row.template_name)
                                    update_reservations();
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "normal",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: string_data,
                                    }}
                                  ></span>
                                </a>
                              );
                            }
                          })()}
                        </>
                      ) : (
                        <>{recievedDemo.status &&                         <a
                          style={{ color: "#1c5db4", cursor: "pointer" }}
                          onClick={(e) => showCreateReservationModal(row)}
                        >
                          Schedule deeptherapy session{" "}
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </a>}</>

                      )}
                    </div>
                    <div className="animation-td status-col w-30">
                      <div
                        className="w-100 d-flex justify-content-center"
                        style={{ gap: "10px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary animation-action-button"
                          onClick={(e) => {
                            settempleteid(row.id);
                            setEditAnimationModalShow(true);
                            // getAnimation(row.id)
                          }}
                          title={"Edit Animation"}
                        >
                          <i className="fa fa-edit"></i>edit
                        </button>

                        <DropdownButton
                          className="animation-action-button"
                          // eventKey={}
                          title={
                            <span>
                              <i className="fa fa-share" aria-hidden="true"></i>
                              share
                            </span>
                          }
                        >
                          <Dropdown.Item>
                            <TwitterShareButton
                              url={`${helper.ApiBaseUrl}`}
                              title={row.name}
                              className="Demo__some-network__share-button"
                            >
                              <i
                                className="fa fa-twitter text-dark"
                                aria-hidden="true"
                              ></i>{" "}
                              Twitter
                            </TwitterShareButton>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <FacebookShareButton
                              url={`${helper.ApiBaseUrl}`}
                              quote={row.name}
                              className="Demo__some-network__share-button"
                            >
                              <i
                                className="fa fa-facebook text-dark"
                                aria-hidden="true"
                              ></i>{" "}
                              Facebook
                            </FacebookShareButton>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <WhatsappShareButton
                              url={`${helper.ApiBaseUrl}`}
                              title={row.name}
                              separator=":: "
                              className="Demo__some-network__share-button"
                            >
                              <i
                                className="fa fa-whatsapp text-dark"
                                aria-hidden="true"
                              ></i>{" "}
                              Whatsapp
                            </WhatsappShareButton>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <LinkedinShareButton
                              url={`${helper.ApiBaseUrl}`}
                              className="Demo__some-network__share-button"
                            >
                              <i
                                className="fa fa-linkedin text-dark"
                                aria-hidden="true"
                              ></i>{" "}
                              LinkedIn
                            </LinkedinShareButton>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <EmailShareButton
                              url={`${helper.ApiBaseUrl}`}
                              subject={row.name}
                              body="body"
                              className="Demo__some-network__share-button"
                            >
                              <i
                                className="fa fa-envelope-o text-dark"
                                aria-hidden="true"
                              ></i>{" "}
                              Email
                            </EmailShareButton>
                          </Dropdown.Item>
                        </DropdownButton>
                        {/* <span
                          className="animation-status"
                          style={{ backgroundColor: "#EA3F95", cursor: "pointer" }}
                          onClick={(e) => helper.handleDownload(`${helper.ApiBaseUrl}${row.video}`, row.name,'.mp4')}
                          title={"Download Animation"}
                        ><i className="fa fa-download" aria-hidden="true"></i></span> */}

                        {/* <span
                          className="animation-status"
                          style={{ backgroundColor: "#0D88F2", cursor: "pointer" }}
                          onClick={(e) => openModel(row)}
                          title={"Schedule Animation"}
                        ><i className="fa fa-calendar" aria-hidden="true"></i></span> */}
                        {recievedDemo.status &&
                        <button
                          type="button"
                          className="btn btn-primary animation-action-button"
                          onClick={(e) => showCreateReservationModal(row)}
                          title={"Schedule Animation"}
                        >
                          <i
                            className="fa fa-calendar-plus"
                            aria-hidden="true"
                          ></i>
                          schedule
                        </button>
                        }
                        
                      </div>
                    </div>
                    {row.live && (
                      <div
                        style={{
                          background: "#000000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          position: "absolute",
                          right: "-2px",
                          top: "-2px",
                          bottom: "-2px",
                          width: 35,
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "9.03407px",
                            lineHeight: "145%",
                            transform: "rotate(-90deg)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span
                            className="animate-flicker"
                            style={{
                              width: 10,
                              height: 10,
                              background: "#EC0202",
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          />
                          <div
                            style={{
                              padding: "0.2rem",
                              display: "inline-block",
                            }}
                          />
                          IN PROGRESS
                        </p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="animation-tr d-flex align-items-center">
                  <div className="animation-td animation-col w-100">
                    <div className="middle_section">
                      <img src={no_animation_icon} alt="No Animation" />
                      {recievedDemo.status ? <>
                      <p>No Animations Created Yet</p>
                      <span>Click “Create Animation” to get started!</span>
                      </> : <>
                      <p>Start by requesting a demo for personalized intake and expert instructions.</p>
                      </>}

                    </div>
                  </div>
                </div>
              )}
              <div className="pagination-row">
                {totalRows <= params.per_page ? null : (
                  <div className="pagination justify-content-center">
                    {/* <span>Showing {startIndex + 1} - {endIndex + 1} of {totalRows}</span> */}
                    <ul className="pagination" style={{ paddingTop: "1%" }}>
                      <li
                        className={
                          params.page === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() => setPagenumber(1)}
                        >
                          <i
                            className="fa fa-fast-backward"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li
                        className={
                          params.page === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() => setPagenumber(params.page - 1)}
                        >
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      {pages.length > 0
                        ? pages.map((page, index) => (
                            <li
                              key={index}
                              role="button"
                              className={
                                params.page === page
                                  ? "page-item active"
                                  : "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                onClick={() => setPagenumber(page)}
                              >
                                {page}
                              </a>
                            </li>
                          ))
                        : ""}
                      <li
                        className={
                          params.page === totalPages
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() => setPagenumber(params.page + 1)}
                        >
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li
                        className={
                          params.page === totalPages
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() => setPagenumber(totalPages)}
                        >
                          <i
                            className="fa fa-fast-forward"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={createAnimationModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="createanimation-modal"
        centered
        onHide={() => {
          setState((state) => ({
            ...state,
            template_id: "",
            image_name: "",
            img_url: "",
            thumb: "",
            video: "",
            user_id: auth.userDeatils.user_id,
            face_postions: [],
            image_size_face: [],
            face_thumbnails: [],
            title: "",
          }));
          setStep(0);
          setIsGenerating(false);
          setCreateAnimationModalShow(false);
        }}
        style={{ zIndex: "999999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div id={loading ? "pageLoader" : ""} />
            <h5 className="modal-title">Create Animation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="uploading-block d-flex align-items-center flex-wrap flex-sm-nowrap">
            {(() => {
              switch (step) {
                case 0:
                  return template_id != "" && img_url != "" ? (
                    <div className="selectface-block-left">
                      <div
                        className="select-face-box"
                        // ref={image_box_ref}
                        ref={(el) => {
                          setImageBox(el);
                        }}
                      >
                        {image_load()}
                      </div>
                      {errormessage != "" ? (
                        <div className="alert alert-danger alert-dismissible mt-2">
                          <span
                            className="close"
                            onClick={() => setErrormessage("")}
                          >
                            &times;
                          </span>
                          {errormessage}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="modal-left-actions">
                        <button
                          type="button"
                          className="btn btn-secondary btn-prev"
                          onClick={() => back_to_main()}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-next"
                          onClick={() => get_faces()}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="uploading-block-left">
                      <div
                        className="input-group"
                        style={{ background: "#e9ecef" }}
                      >
                        <>
                          <input
                            type="file"
                            className="form-control"
                            id="chooseImage"
                            accept="image/png, image/jpg, image/jpeg"
                            name="image"
                            onChange={(e) => changefile(e)}
                          />
                          <label className="input-group-text d-flex align-items-center justify-content-center flex-column w-100">
                            <span className="upload-icon">
                              <img src={Uplaod_icon} alt="Uplaod" />
                            </span>
                            <span className="input-text mb-4">
                              Choose an image or drag it here
                            </span>
                            {/* <span className="upload-icon">
                                <img src={Uplaod_sample} alt="Uplaod Sample" />
                              </span> */}
                            <span
                              className="input-text1 mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              The image must contain a frontal face which is
                              clearly visible
                            </span>
                          </label>
                        </>
                      </div>
                      {errormessage != "" ? (
                        <div className="alert alert-danger alert-dismissible mt-2">
                          <span
                            className="close"
                            onClick={() => setErrormessage("")}
                          >
                            &times;
                          </span>
                          {errormessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                case 1:
                  return (
                    <div className="selectface-block-left">
                      <div className="select-face-box">
                        <div className="img_overlay">
                          <img src={img_url} alt="Uplaod" />
                        </div>
                        {face_postions.length > 0
                          ? face_postions.map((item, index) =>
                              face_detection(index, item)
                            )
                          : ""}
                      </div>
                      <div className="modal-left-actions">
                        <button
                          type="button"
                          className="btn btn-secondary btn-prev"
                          onClick={() => setStep(0)}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-next"
                          onClick={() => setStep(2)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  );
                case 2:
                  return isGenerating ? (
                    <div className="selectface-block-left">
                      <div className="select-face-box">
                        <div className="img_overlay">
                          <img
                            src={img_url}
                            alt="Uplaod"
                            style={{ minHeight: "335px" }}
                          />
                        </div>
                        <div className="generation_postion_div">
                          <div
                            className="card"
                            style={{ width: "auto", height: "auto" }}
                          >
                            <img
                              className="card-img-top"
                              src={`${helper.ApiBaseUrl}${face_thumbnails[facekey]}`}
                              alt="Bologna"
                            />
                            <div className="card-body">
                              <p className="card-bottom-title">{mood}</p>
                            </div>
                          </div>
                          <p
                            style={{
                              color: "#fff",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            Generating preview
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="modal-left-actions">
                        <p style={{ marginTop: "20px" }}>
                          Please wait until process is finished
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="selectface-block-left">
                      <div className="select-face-box grid-popup">
                        {/* <div className="row">                             */}
                        <div
                          className="mb-2 position-relative"
                          style={{ background: "black", borderRadius: "10px" }}
                        >
                          <video
                            autoPlay
                            muted
                            loop
                            controls
                            className="video_center"
                            style={{ height: "300px", position: "relative" }}
                            ref={videoRef}
                          >
                            <source
                              src={`${
                                helper.ApiBaseUrl
                              }download/emotions_static/${mood}_10.mp4?${Math.random()}`}
                            />
                          </video>
                          <span className="watermark top">sample video</span>
                          <span className="watermark">sample video</span>
                        </div>

                        {emotionsOptions.length > 0 ? (
                          <Select
                            defaultValue={{
                              value: "agreeable",
                              label: "agreeable",
                              id: "",
                            }}
                            options={emotionsOptions}
                            onChange={onSelectChange}
                            clearable={false}
                          />
                        ) : (
                          ""
                        )}
                        <span className="input-text1 mb-4">
                          Select the mood to continue
                        </span>
                        {/* </div> */}
                      </div>
                      <div className="modal-left-actions">
                        <button
                          type="button"
                          className="btn btn-secondary btn-prev"
                          onClick={() => {
                            setErrormessage("");
                            setStep(1);
                          }}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-next"
                          onClick={() => templete_preview()}
                        >
                          Next
                        </button>
                      </div>
                      {errormessage != "" ? (
                        <div className="alert alert-danger alert-dismissible mt-2">
                          <span
                            className="close"
                            onClick={() => setErrormessage("")}
                          >
                            &times;
                          </span>
                          {errormessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                case 3:
                  return (
                    <div className="selectface-block-left">
                      <div className="select-face-box">
                        <video width="502" height="500" controls>
                          <source src={video} />
                        </video>
                      </div>
                      <div
                        className="modal-left-actions"
                        style={{ display: "flex" }}
                      >
                        <div className="div-final-vi">
                          <Label className="form-label">Name</Label>
                          <Input
                            type="text"
                            name="title"
                            defaultValue={image_name}
                            invalid={errors.title ? true : false}
                            onChange={(e) => onChangeValueState(e)}
                          />
                          <FormFeedback>{errors.title}</FormFeedback>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-next"
                          onClick={(e) => save_visulation(e)}
                        >
                          Save
                        </button>
                      </div>
                      {errormessage != "" ? (
                        <div className="alert alert-danger alert-dismissible mt-2">
                          <span
                            className="close"
                            onClick={() => setErrormessage("")}
                          >
                            &times;
                          </span>
                          {errormessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                case 4:
                  return (
                    <div className="selectface-block-left">
                      <div className="select-face-box"></div>
                      <div className="modal-left-actions">
                        <button
                          type="button"
                          className="btn btn-primary btn-next"
                          onClick={() => get_faces()}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  );
              }
            })()}

            <div className="uploading-block-right mt-4 mt-sm-0">
              <h5 className="upload-step-title" style={{ paddingLeft: "35px" }}>
                Steps:
              </h5>
              <ol className="upload-step-list" style={{ listStyle: "none" }}>
                <li
                  className={
                    step > 0 ? "upload-step completed" : "upload-step active"
                  }
                >
                  {step > 0 ? (
                    <i className="fa fa-check completed-icon"></i>
                  ) : (
                    " "
                  )}{" "}
                  1. Upload image
                </li>
                <li
                  className={
                    step > 1
                      ? "upload-step completed"
                      : step == 1
                      ? "upload-step active"
                      : "upload-step"
                  }
                >
                  {step > 1 ? (
                    <i className="fa fa-check completed-icon"></i>
                  ) : (
                    ""
                  )}{" "}
                  2. Select face
                </li>
                <li
                  className={
                    step > 2
                      ? "upload-step completed"
                      : step == 2
                      ? "upload-step active"
                      : "upload-step"
                  }
                >
                  {step > 2 ? (
                    <i className="fa fa-check completed-icon"></i>
                  ) : (
                    " "
                  )}{" "}
                  3. Select Mood
                </li>
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <UpdateAnimation
        modal={editAnimationModalShow}
        setModal={setEditAnimationModalShow}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
        templeteid={templeteid}
        mood={mood}
        settempleteid={settempleteid}
        createschedule={showCreateReservationModal}
        openschedule={showEditReservationModal}
      />
      <AddSessionForm
        modal={createReservationModalShow}
        setModal={setCreateReservationModalShow}
        reservation_id={reservation_id}
        setReservation_id={setReservation_id}
        animationData={animationData}
        formType={"virtualizationForm"}
        templeteid={templeteid}
        settempleteid={settempleteid}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
      />
      <NotStartedSession
        modal={notStarteModal}
        setModal={setNotStarteModal}
        id={selectedEventId}
        dashboard={true}
      />
    </>
  );
}
