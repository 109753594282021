import { useState, React, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { validateAll } from "indicative/validator";
import {  Input, FormFeedback } from "reactstrap";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import PageTitle from "../../Components/PageTitle";
import login_img from '../../assets/images/login-img.svg'
import loginImage from "../../assets/images/login-logo.png";
// ----------------------------------------------------------------------

export default function ResetPassword() {
  const params = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    password: "",
    password_confirmation:"",
    errors: {},
  });
  const { password, errors } = state;

  const onChangeValue = (e) => {
    let value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const submitResetPassword = (e) => {
    e.preventDefault();
    const rules = {
      password: "required|string|min:6|confirmed",
      password_confirmation: "required|string|min:6"
    };
    const message = {
      "password.required": "Password is Required.",
      "password.string": "Password must be String.",
      "password.min": "Password must be more then 6 character.",
      "password.confirmed": "Password and Confirm password does not match.",
      "password_confirmation.required": "Confirm Password is Required.",
      "password_confirmation.string": "Confirm Password must be String.",
      "password_confirmation.min": "Confirm Password must be more then 6 character.",
    };
    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: {},
        });

        let data = {};
        data.uid = params.uid;
        data.token = params.token;
        data.new_password = password;

        AxiosApi.withoutToken("reset_password/done", "post", data)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              setLoading(false);
              setState({
                ...state,
                password: '',
                password_confirmation: '',
                errors: {},
              });
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };
  useEffect(() => {
    return function() {
      /**
       * Add cleanup code here
       */
    };
  });
  return (
    <div className="page-wrapper login-pagewrapper">
      <PageTitle title="DeepTherapy | Reset Password" />
      <div id={loading ? "pageLoader" : ""} />
            <main id="page-main">
                <div className="login-wrapper d-flex justify-content-center align-items-center flex-column">
                    <div className="login-container">
                        <div className="row g-0">
                            <div className="login-left col-md-5">                                
                                <div className="login-image-col ">
                                    <span className="position-relative d-inline-block">
                                    <img src={loginImage} alt="Welcome back" />
                                      <div className="login-logo-text">
                                          DeepTherapy
                                      </div>
                                    </span>
                                </div>
                            </div>

                            <div className="login-right p-3 col-md-7  d-flex justify-content-center align-items-center flex-column">
                                <form className="login-form">
                                    <div className="login-form-block">
                                        <h1 className="login-title text-center">
                                            Reset Password 
                                        </h1>
                                        <div className="mb-3 position-relative">
                                            <Input 
                                            type={showPassword ? "text" : "password"}
                                            invalid={errors.password ? true : false}
                                             id="password" placeholder="Password"
                                             name="password"
                                             onChange={(e) => onChangeValue(e)}
                                            />
                                            <FormFeedback>{errors.password}</FormFeedback>
                                            <span className="password-show position-absolute" onClick={(e) => setShowPassword(!showPassword)}>
                                              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                            </span>
                                        </div>

                                        <div className="mb-3 position-relative">
                                            <Input 
                                            type={showConfirmPassword ? "text" : "password"}
                                            invalid={errors.password_confirmation ? true : false}
                                             id="password_confirmation" placeholder="Confirm Password"
                                             name="password_confirmation"
                                             onChange={(e) => onChangeValue(e)}
                                            />
                                            <FormFeedback>{errors.password_confirmation}</FormFeedback>
                                            <span className="password-show position-absolute" onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}>
                                                <i className={showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                            </span>
                                        </div>

                                        <div className="form-action text-center">
                                            <button type="button" className="btn btn-primary btn-login w-100" onClick={(e) => submitResetPassword(e)}>Change Password</button>
                                            <div className="form-action-link mt-2">
                                                Do have an account? <Link to="/login" className="forgot-link" title="Forgot password?">Sign In?</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="login-footer text-center">
                        © {new Date().getFullYear()} DeepTherapy. All rights reserved.
                    </div>
                </div>
            </main>
        </div>
  );
}
