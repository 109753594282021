import React, { useEffect, useState } from 'react';
import PageTitle from '../Components/PageTitle';
import AxiosApi from '../Helper/axios.api';
import { useSelector } from "react-redux";

const UsersTrainings = () => {
    const auth = useSelector((state) => state.auth);
    const [data, setData] = useState({});
    const fetchData = async () => {
        try {
            const response = await AxiosApi.withToken(auth.accessToken, "trainings", "get", data);
            console.log(response);
            setData(response);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const setTrainingAsReceived = async (trainingId, userId) => {
        console.log(trainingId, "trainingId");

        try {
            const payload = {
                training_id: trainingId,
                user_id: userId
            };
            console.log(payload, "payload");
            const response = await AxiosApi.withToken(auth.accessToken, "trainings", "patch", payload);
            console.log(response);
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    const renderTableHeader = () => (
        <div className="animation-thead d-flex justify-content-between">
            {['email', 'created_at', 'requested', 'verified', 'Action'].map((header, index) => (
                <div 
                    key={index}
                    className={`animation-th ${header.replace(' ', '-')}-col d-flex align-items-center justify-content-center w-${index === 4 ? '30' : '25'}`}
                >
                    {header}
                </div>
            ))}
        </div>
    );

    const renderTableRow = (data, index) => (
        <div className="animation-tr d-flex justify-content-between" key={index}>
            <div className="animation-td title-col d-flex align-items-center justify-content-center w-25">
                {data.email}
            </div>
            <div className="animation-td title-col d-flex align-items-center justify-content-center w-25">
                {data.create_at}
            </div>
            <div className="animation-td title-col d-flex align-items-center justify-content-center w-25">
                {data.requested ? "Requested" : "Not requested"}
            </div>
            <div className="animation-td title-col d-flex align-items-center justify-content-center w-25">
                {data.training_recieved ? "Verified" : "Not verified"}
            </div>
            <div className="animation-td status-col d-flex align-items-center justify-content-center w-30">
                <button 
                    type="button" 
                    className="btn btn-primary btn-sm"
                    onClick={() => setTrainingAsReceived(data.training_id, data.user_id)}
                    disabled={data.training_recieved}
                >
                    mark as verified                    
                </button>
            </div>
        </div>
    );

    return (
        <>
            <PageTitle title="DeepTherapy | Users trainings" />
            <div className="virtualization">
                <div className="card w-100">
                    <div className="card-body">
                        {renderTableHeader()}
                        <div className="animation-tbody">
                            {data.data && data.data.map(renderTableRow)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersTrainings;
