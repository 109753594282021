import axios from "axios";
import helper from "./Helper";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { logout } from "../Action/Auth.action";

function AxiosApiCall() {

  const showGeneralErrorMessage = () => {
    const toastId = 'axios-unknown-error'
    if (!toast.isActive(toastId)) {
      toast.error("We're experiencing a technical issue. Please try again in a few minutes.", {
        toastId,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const withToken = async (authToken, url, type, data, version = 'api/v3/') => {
    const config = {
      method: type,
      url: `${helper.ApiBaseUrl}${version}${url}`,
      data: data,
      headers: {
        "Authorization": authToken,

      },
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })
      .catch((error) => {
        if (error.response.data) {
          let res = error.response.data;
          if (res.message === 'Unauthorised user!') {
            const toastId = 'session-expired'
            if (!toast.isActive(toastId)) {
              toast.error("Session expired. Please login again.!", {
                toastId,
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            result.login = false;
            // dispatch(logout());
            // navigate("/login", { replace: true });
          }
        }
        if (error.response) {
          showGeneralErrorMessage()
        } else if (error.request) {
          showGeneralErrorMessage()
        } else {
          showGeneralErrorMessage()
        }
      });
    return result;
  };
  const withoutToken = async (url, type, data, version = 'api/v3/') => {
    const config = {
      method: type,
      url: `${helper.ApiBaseUrl}${version}${url}`,
      data: data,
      /*headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        mode: "no-cors",
      },*/
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })

      .catch((error) => {
        const toastId = 'axios-unknown-error'
        if (error.response) {
          showGeneralErrorMessage()
        } else if (error.request) {
          showGeneralErrorMessage()
        } else {
          showGeneralErrorMessage()
        }
      });
    return result;
  };

  const withTokenFullUrl = async (authToken, url, type, data) => {
    const config = {
      method: type,
      url: `${url}`,
      data: data,
      headers: {
        "Authorization": authToken,

      },
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })
      .catch((error) => {
        if (error.response) {
          showGeneralErrorMessage()
        } else if (error.request) {
          showGeneralErrorMessage()
        } else {
          showGeneralErrorMessage()
        }
      });
    return result;
  };
  const withoutTokenFullUrl = async (url, type, data) => {
    const config = {
      method: type,
      url: `${url}`,
      data: data,
      /*headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        mode: "no-cors",
      },*/
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })

      .catch((error) => {
        if (error.response) {
          showGeneralErrorMessage()
        } else if (error.request) {
          showGeneralErrorMessage()
        } else {
          showGeneralErrorMessage()
        }
      });
    return result;
  };
  return {
    withToken,
    withoutToken,
    withTokenFullUrl,
    withoutTokenFullUrl
  };
}

const AxiosApi = AxiosApiCall();
export default AxiosApi;
