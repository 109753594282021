import { useSearchParams, Link } from "react-router-dom";
import PageTitle from "../Components/PageTitle";
import Success from "../assets/img/success.png";

const SuccessPayment = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  return (
    <>
      <PageTitle title="DeepTherapy | Success Payment" />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <div
          className="card w-50"
          style={{
            border: 0,
            maxWidth: 500,
	    boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, 0.1)",
	    padding: "5px 30px 30px 30px"
          }}
        >
          <img src={Success} />
          <h1 className="login-title text-center" style={{ marginTop: -30, marginBottom: 10 }}>
            Thank you for your purchase
          </h1>
          <p
	    style={{
              fontSize: 12
	    }}>
            Payment was successful
            <br />{searchParams.get("credits")}{" "}
            credits have been added to your account.
          </p>
          <Link to="/">
            <button
              style={{
                fontSize: 12,
                fontWeight: "bold",
		maxWidth: 400,
		marginTop: 5,
		height: 44,
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
              }}
              type="button"
              className="btn btn-primary btn-login w-75"
            >
              Back to home page
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuccessPayment;
