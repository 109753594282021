import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateTotalCoins } from "../Action/Auth.action";
import PageTitle from "../Components/PageTitle";
import AxiosApi from "../Helper/axios.api";
import StripeCheckoutForm from "./StripeCheckoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, Elements, CardElement } from "@stripe/react-stripe-js";
import helper from "../Helper/Helper";

    

export default function Payment() {
    const stripe = loadStripe(helper.STRIPE_KEY);
    const dispatch = useDispatch();
    const auth = useSelector((user) => user.auth);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    let { id } = useParams();

    const options = {
        // passing the client secret obtained from the server
        //clientSecret: '{{CLIENT_SECRET}}',
    };

      
    useEffect(() => {
        (async () => {
            await getSubscriptionPlans();
        })()

        return function () {
        };

    }, [id]);
    const getSubscriptionPlans = async () => {
        setLoading(true);
        await AxiosApi.withToken(auth.accessToken, "subscription_plan", "get", {})
            .then(async (res) => {
                if (res.status) {

                    if (res.data.length > 0) {
                        let final_data = [];
                        Promise.all(
                            res.data.map(async (result) => {
                                if (result.id == id) {
                                    // final_data.push(result);
                                    setSubscriptionPlans(result);
                                }

                            })
                        )


                    }
                } else {
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            })
            .catch((errors_res) => {
                setLoading(false);
            });
    }
    /*const payforcoin = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {};
        data.transaction_id = "test_id";
        data.user_id = auth.userDeatils.user_id;
        data.subscription_plan = subscriptionPlans.id;
        AxiosApi.withToken(auth.accessToken, "subscription_plan/buy", "post", data)
            .then((res) => {
                if (res.status) {
                    toast.success(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    dispatch(updateTotalCoins(res.data))
                } else {
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);


            })
            .catch((errors_res) => {
                setLoading(false);
            });
    }*/


   

    return (
        <>
        <PageTitle title="Payment" />
            {
                subscriptionPlans.id ? (
            
            
            <div className="container">
                <div className="row m-0 pt-3">
                    <div className="col-md-7 col-12">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="row box-right">
                                    <div className="col-md-8 ps-0 ">
                                        <p className="ps-3 textmuted fw-bold h6 mb-0">{subscriptionPlans.name}</p>
                                        <p className="h1 fw-bold d-flex"> <span className=" fas fa-euro-sign textmuted pe-1 h6 align-text-top mt-1"></span>{subscriptionPlans.price} </p>
                                        {/* <p className="ms-3 px-2 bg-blue">+10% since last month</p>                                         */}
                                    </div>
                                    {/* <div className="col-md-4"> */}
                                        {/* <p className="ps-3 textmuted fw-bold h6 mb-0">100 Coins</p> */}
                                        {/* <p className="p-blue"> <span className="fas fa-circle pe-2"></span>{subscriptionPlans.number_coin} credits </p> */}
                                        
                                        {/*  <p className="fw-bold mb-3"><span className="fas fa-dollar-sign pe-1"></span>1254 <span className="textmuted">.50</span> </p>
                                        <p className="p-org"><span className="fas fa-circle pe-2"></span>On drafts</p>
                                        <p className="fw-bold"><span className="fas fa-dollar-sign pe-1"></span>00<span className="textmuted">.00</span></p> */}
                                    {/* </div> */}
                                    <p className="ps-3 h6 mb-0"><strong>{subscriptionPlans.number_coin} x DeepTherapy credits</strong></p>
                                    {/* <p className="p-blue"> <span className="fas fa-circle pe-2"></span>{subscriptionPlans.number_coin} credits </p> */}
                                    <p className="ps-3 textmuted h7 mb-0">{subscriptionPlans.description}</p>
                                    <p className="ps-3 mt-3 textmuted h7 mb-0">Total price: <span className="fas fa-euro-sign h8"></span> {Math.round(subscriptionPlans.price * 121) / 100} (with 21% VAT)</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-5 col-12 ps-md-5 p-0 ">
                        <div className="box-left">

                            <div className="">
                                <p className="h7 fw-bold mb-1">Pay this Invoice</p>
                                <p className="textmuted h8 mb-2">Make payment for this invoice by filling in the details</p>
                                
                                <Elements stripe={stripe} >
                                    <StripeCheckoutForm subscriptionPlans_id={subscriptionPlans.id} subscriptionPlans={subscriptionPlans} />
                                </Elements>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                ) : ""}
        </>
    );
}
