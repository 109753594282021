import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosApi from "../Helper/axios.api";
import helper from "../Helper/Helper";



export default function Completed() {
    const dispatch = useDispatch();
    const auth = useSelector((user) => user.auth);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            // const queryParams = new URLSearchParams(window.location.search);
            // const payment_intent = queryParams.get('payment_intent');
            // const payment_intent_client_secret = queryParams.get('payment_intent_client_secret');
            // const redirect_status = queryParams.get('redirect_status');
            // if (redirect_status === 'succeeded') {
            //     toast.success('The payment was confirmed successfully!', {
            //         position: "top-center",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });
            // } else {
            //     toast.error('The payment was faild!', {
            //         position: "top-center",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });
            // }


            // navigate("/dashboard", { replace: true });
            await getSubscriptionPlans();
        })()

        return function () {
        };

    }, []);

    const getSubscriptionPlans = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams(window.location.search);
        const payment_intent = queryParams.get('payment_intent');
        const payment_intent_client_secret = queryParams.get('payment_intent_client_secret');
        const redirect_status = queryParams.get('redirect_status');
        let data = {}
        data.payment_intent = payment_intent
        data.payment_intent_client_secret = payment_intent_client_secret
        data.redirect_status = redirect_status
        data.user_id = auth.userDeatils.user_id
        await AxiosApi.withToken(auth.accessToken, "subscription_plan/ideal_callback", "post", data)
            .then(async (res) => {
                if (res.status) {
                    toast.success('The payment was confirmed successfully!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
                navigate("/dashboard", { replace: true });

            })
            .catch((errors_res) => {
                setLoading(false);
            });
    }

    return (
        <>

        </>
    );
}
