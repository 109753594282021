import React, { useEffect, useState } from "react";
import { DropdownButton, Nav, Navbar, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { logout } from "../../../Action/Auth.action";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../../assets/images/logo-blue.png'
export default function NavBar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  
  const [fullName, setFullName] = useState(auth.name);
  const [firstName, setFirstName] = useState(auth.userDeatils.name);
  const [lastName, setLastName] = useState(auth.userDeatils.name);
  const logOut = () => {
    confirmAlert({
      title: "Logout",
      message: "Are you sure to do logout ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(logout());
            navigate("/login", { replace: true });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(()=>{
    let name = auth.name.split(" ");
    setFullName(auth.name)
    setFirstName(name[0])
    if(name.length > 2){
      setLastName(name[name.length-1] ? name[name.length-1] : "")  
    }else{
      setLastName(name[1] ? name[1] : "")
    }
    
    return function() {
      /**
       * Add cleanup code here
       */
    };
  },[auth.name]);
  return (
    <>
      {/* <!-- Navbar --> */}
      {/* <header className="mb-3 border-bottom container-fluid">
        <div className="d-flex flex-wrap">
          <ul className="nav col-12 col-sm-auto me-sm-auto mb-sm-0"></ul>

          <Navbar brand="React-Bootstrap">
            <Nav right="true">
              <DropdownButton
                variant={"bg-transparent"}
                title={
                  <span>
                    <img
                      src="https://github.com/mdo.png"
                      alt="mdo"
                      className="rounded-circle"
                      width="32"
                      height="32"
                    />{"         "}
                    {auth.userDeatils.name}
                  </span>
                }
              >
                <Dropdown.Item>
                  <i className="fa fa-envelope fa-fw"></i> User Profile
                </Dropdown.Item>
                <Dropdown.Item>
                  <i className="fa fa-gear fa-fw"></i> Settings
                </Dropdown.Item>
                <Dropdown.Item divider="true" />
                <Dropdown.Item onClick={() => logOut()}>
                  <i className="fa fa-sign-out fa-fw"></i> Logout
                </Dropdown.Item>
              </DropdownButton>
            </Nav>
          </Navbar>
        </div>
      </header> */}

      <header
        id="header"
        className="page-header d-flex align-items-center justify-content-between p-1 flex-wrap"
      >
        <div className="logo-col d-flex align-items-center p-1 ">
          <a href="https://deeptherapy.ai" className="logo-link d-flex align-items-center">
            <img
              src={logo}
              alt="DeepTherapy"
              width="36"
              height="36"
            />
            <span className="logo-text">DeepTherapy</span>
          </a>
        </div>
        <div className="mobile-toggel d-block d-md-none px-4" role="button" onClick={() => props.setShowmenu(!props.showmenu)}>
        <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div className="header-right d-flex align-items-center">
          {/* <div className="customer-post">MF</div> */}
          <Navbar brand="React-Bootstrap" className="drp-navbar-top">
            <Nav right="true"  className="drp-nav-top">
              <DropdownButton
                variant={"bg-transparent"}
                className="drp-navbar-name"
                title={
                  <>
                  <div className="customer-post" style={{width:'35px',height:'35px'}}>
                        {firstName.charAt(0)}{lastName.charAt(0)}
                    </div>
                    <span>{fullName}</span>
                  </>
                }
              >
                <Dropdown.Item as={Link} to="/profile">
                  {/* <Link to="/profile" className="video-item"> */}
                    <i className="fa fa-gear fa-fw"></i> User Profile
                  {/* </Link> */}
                </Dropdown.Item>
                
                <Dropdown.Item onClick={() => logOut()}>
                  <i className="fa fa-sign-out fa-fw"></i> Logout
                </Dropdown.Item>
              </DropdownButton>
            </Nav>
          </Navbar>
          
        </div>
      </header>
    </>
  );
}
