import { useState, React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateAll } from "indicative/validator";
// import "./login.scss";
import { Label, Input, FormFeedback} from "reactstrap";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import PageTitle from "../../Components/PageTitle";
// ----------------------------------------------------------------------

export default function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    user_name: "",
    errors: {},
  });
  const { email, password, user_name,  errors } = state;

  const tou_link = "https://deeptherapy.ai/term-of-use/"
  const pp_link = "https://deeptherapy.ai/privacy-policy/"

  const onChangeValue = (e) => {
    let value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const submitRegisterForm = (e) => {
    e.preventDefault();
    const rules = {
      email: "required|email",
      user_name: "required",
      password: "required",
      
    };
    const message = {
      "user_name.required": "Name is Required.",
      "email.required": "Email is Required.",
      "email.email": "Please enter valid email.",
      "password.required": "Password is Required.",
      
    };
    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: {},
        });

        let rgdata = {};
        rgdata.name = user_name;
        rgdata.email = email;
        // rgdata.website = "https://hello.com";
        rgdata.password = password;
        setLoading(true);
        AxiosApi.withoutToken("registration", "post", rgdata)
          .then((res) => {
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate("/login", { replace: true });
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setLoading(false);
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };

  useEffect(() => {
    return function() {
      /**
       * Add cleanup code here
       */
    };
  });
  return (
    <div className="page-wrapper signup-pagewrapper">
      <PageTitle title="DeepTherapy | Register" />
      <div id={loading ? "pageLoader" : ""} />
            <main id="page-main">
                <div className="login-wrapper d-flex justify-content-center align-items-center flex-column">
                    <div className="login-container">
                        <div className="row g-0">
                            <div className="login-left col-md-5">                                
                                <div className="login-image-col ">
                                    <span className="position-relative d-inline-block">
                                        <img src="images/login-logo.png" alt="Sign up"  />

                                        <div className="login-logo-text">
                                          DeepTherapy
                                        </div>
                                    </span>
                                </div>
                                
                            </div>
                            <div className="login-right p-3 col-md-7  d-flex justify-content-center align-items-center flex-column">
                                <form className="login-form">
                                    <div className="login-form-block">
                                        <h1 className="login-title text-center">
                                            Sign Up 
                                        </h1>
                                        <div className="mb-1">
                                            <Label className="form-label">Name</Label>
                                            <Input type="text" 
                                            invalid={errors.user_name ? true : false}
                                            name="user_name" 
                                            placeholder="Enter Full name"
                                            onChange={(e) => onChangeValue(e)}
                                            />
                                            <FormFeedback>{errors.user_name}</FormFeedback>
                                        </div>
                                        <div className="mb-1">
                                            <Label className="form-label">Email</Label>
                                            <Input type="email" 
                                            invalid={errors.email ? true : false}
                                            name="email"
                                            onChange={(e) => onChangeValue(e)}
                                            placeholder="Enter a valid email address"
                                            />
                                            <FormFeedback>{errors.email}</FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Password</Label>
                                            <div className="position-relative">
                                                <Input 
                                                type={showPassword ? "text":"password"} name="password" 
                                                invalid={errors.password ? true : false}
                                                onChange={(e) => onChangeValue(e)}
                                                placeholder="Enter password"
                                                />
                                                <span className="password-show position-absolute" onClick={(e) => setShowPassword(!showPassword)}>
                                                  <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                                </span>
                                                <FormFeedback>{errors.password}</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="term-condition-row mb-4">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="term" checked={ term ? true: false} onChange={(e) => setTerm(!term)} />
                                                <label className="form-check-label" onClick={(e) => setTerm(!term)}>Yes, I agree with the <a href={tou_link} target="_blank"><strong>term and conditions</strong></a> and <a href={pp_link} target="_blank"><strong>privacy policy</strong></a>.</label>
                                            </div>
                                        </div>
                                        <div className="form-action text-center">
                                            <button type="button" onClick={(e) => submitRegisterForm(e)} className="btn btn-primary btn-register w-100">Register</button>
                                            <div className="form-action-link mt-2">
                                                Already have an account? <Link to="/login" className="login-link" title="Login">Login</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="login-footer text-center">
                        © {new Date().getFullYear()} DeepTherapy. All rights reserved.
                    </div>
                </div>
            </main>
        </div>
  );
}
