import { Helmet } from "react-helmet-async";


export default function PageTitle(props) {
  return (
    <Helmet>
        <title>{props.title ? props.title : ""}</title>
      </Helmet>
  );
}

