import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Elements, CardElement, useStripe, useElements, IdealBankElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import AxiosApi from "../Helper/axios.api";
import { updateTotalCoins } from "../Action/Auth.action";
import IdealBankSection from './IdealBankSection';
import helper from "../Helper/Helper";

const StripeCheckoutForm = (props) => {

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const elements = useElements();
  const auth = useSelector((user) => user.auth);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('card');

  const payMoney = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const subscription_plan = props.subscriptionPlans_id;

    const card = elements.getElement(CardElement);
    const idealBank = elements.getElement(IdealBankElement);
    const accountholderName = e.target['accountholder-name'];
    setPaymentLoading(true)
    let result;
    if (type == 'card') {
      result = await stripe.createToken(card);
    } else if (type == 'ideal') {
      let data = {};
      data.user_id = auth.userDeatils.user_id;
      data.amount = props.subscriptionPlans.price * 100;
      data.currency = 'eur';
      data.subscription_plan = props.subscriptionPlans_id;
      await AxiosApi.withToken(auth.accessToken, "subscription_plan/ideal_token", "post", data)
        .then(async (res) => {
          if (res.data) {
            result = await stripe.confirmIdealPayment(`${res.data.client_secret}`, {
              payment_method: {
                ideal: idealBank,
                billing_details: {
                  name: accountholderName.value,
                },

              },
              return_url: `${helper.siteUrl}/completed`,
            });
          }

        })

      if (result.error) {
        setPaymentLoading(false)
        toast.error(result.error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(result)
      }

      return false;

    }


    if (result.error) {
      setPaymentLoading(false)
      toast.error(result.error.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {

      let data = {};
      data.user_id = auth.userDeatils.user_id;
      data.token = result.token.id;
      data.subscription_plan = subscription_plan;

      setLoading(true);
      AxiosApi.withToken(auth.accessToken, "subscription_plan/buy", "post", data)
        .then((res) => {

          if (res.status) {

            console.log(res);
            toast.success(res.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            dispatch(updateTotalCoins(res.data.total_coin))
            navigate("/dashboard", { replace: true });

          } else {

            toast.error(res.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setLoading(false);
          }

        })
        .catch((errors_res) => {
          setLoading(false);
        });
    }
  };

  return (
    <div
      style={{
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      <form
        style={{
          display: "block",
          width: "100%",
        }}

        onSubmit={payMoney}
      >
        <div className="row">
          <div className="col-sm-6">
            <div className={type == 'card' ? "card card-active" : 'card'} style={{ cursor: 'pointer' }} onClick={() => setType('card')}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <h5 className="card-title text-center m-0">Card</h5>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={type == 'ideal' ? "card card-active" : 'card'} style={{ cursor: 'pointer' }} onClick={() => setType('ideal')}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <h5 className="card-title text-center m-0">iDEAL</h5>

              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="pt-2"
        >
          {type == 'card' ? (
            <div className="w-100">
              <div className="profile-form-block">
                <label className="form-label form-label">Card Details</label>
                <CardElement
                  className="card"
                  options={{
                    style: {
                      base: {
                        backgroundColor: "white"
                      }
                    },
                  }}
                />
              </div>
            </div>

          ) : (
            <div className="w-100">
              <div className="profile-form-block">
                <label className="form-label form-label">Name</label>
                <input name="accountholder-name" type="text" className="form-control" />
              </div>
              <div className="form-row">
                <IdealBankSection />
              </div>
            </div>
          )
          }

          <button
            className="pay-button"
            disabled={isPaymentLoading}
          >
            {isPaymentLoading ? "Loading..." : "Pay"}
          </button>
        </div>
      </form >
    </div >
  );
};
export default StripeCheckoutForm;