import {
    UPDATE_USERDETAILS,
    LOGOUT,
    USERLOGIN,
    UPDATE_TOTAL_COIN
} from "../Type/auth.type";

export const signIn = (foundUser) => ({
  type: USERLOGIN,
  response: foundUser,
});
export const updateUserDetails = (foundUser) => ({
  type: UPDATE_USERDETAILS,
  response: foundUser,
});
export const updateTotalCoins = (foundUser) => ({
  type: UPDATE_TOTAL_COIN,
  response: foundUser,
});
export const logout = () => ({
  type: LOGOUT
});
