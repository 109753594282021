import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import { store } from "./store";
import Routes from "./Routes/Routes";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Provider store={store}>
        <Routes />
        <ToastContainer />
      </Provider>
  );
}

export default App;
