import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosApi from "../Helper/axios.api";
import { toast } from "react-toastify";
import PageTitle from "../Components/PageTitle";
import { validateAll } from "indicative/validator";
import { Input, FormFeedback } from "reactstrap";
import { updateUserDetails } from "../Action/Auth.action";

export default function Profile() {
  const dispatch = useDispatch();
  const auth = useSelector((user) => user.auth);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    zoom_link: "",
    errors: {},
  });

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    (async () => {
      await getUser(auth.userDeatils.user_id);
    })();
  }, []);

  const { name, email, password, zoom_link, errors } = state;

  const onChangeValue = (e) => {
    let value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const submitUserProfile = (e) => {
    e.preventDefault();
    const rules = {
      name: "required|string",
      email: "required|email",
      password: "string|min:6",
      zoom_link: "url",
    };
    const message = {
      "name.required": "Name is Required.",
      "email.string": "Email must be string.",
      "email.required": "Email is Required.",
      "email.email": "Please enter valid email.",
      "password.string": "Password must be String.",
      "password.min": "Password must be more then 6 character.",
      "zoom_link.required": "DeepTherapy Link is Required.",
      "zoom_link.url": "Enter a valid URL.",
    };

    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          errors: {},
        });
        setLoading(true);
        let data = {};
        data.user_id = auth.userDeatils.user_id;
        data.name = name;
        if (password && password !== "") {
          data.password = password;
        }
        data.website = "www.ggogle.com";
        data.default_zoom_link = zoom_link;

        AxiosApi.withToken(auth.accessToken, "user/edit", "post", data)
          .then((res) => {
            if (res.status) {
              dispatch(updateUserDetails(data));
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setIsUpdate(!isUpdate);
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setLoading(false);
          })
          .catch((errors_res) => {
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        const formattedErrors = {};
        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        setState({
          ...state,
          errors: formattedErrors,
        });
      });
  };

  const getUser = async (id) => {
    let data = {};
    await AxiosApi.withToken(auth.accessToken, "user/" + id, "get", data)
      .then(async (res) => {
        if (res.status) {
          setState({
            ...state,
            name: res.data.name,
            email: res.data.email,
            zoom_link: res.data.default_zoom_link,
          });
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageTitle title="DeepTherapy | Profile" />
      <div className="card w-100">
        <div id={loading ? "pageLoader" : ""} />

        <div className="card-header bg-white d-flex align-items-center justify-content-between flex-wrap">
          <div className="card-header-left">
            <div className="card-header-top d-flex align-items-center flex-wrap mb-2">
              <div className="card-title">My Profile</div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <form className="profile-form">
            <div className="profile-form-block">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-2">Name</label>
                    <Input
                      type="text"
                      className="form-control mb-2"
                      invalid={errors.name ? true : false}
                      placeholder=""
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => onChangeValue(e)}
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-2">Email</label>
                    <Input
                      type="email"
                      className="form-control mb-2 disabled-email"
                      invalid={errors.email ? true : false}
                      placeholder=""
                      id="email"
                      name="email"
                      value={email}
                      disabled="disabled"
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-2">Password</label>
                    <Input
                      type="password"
                      className="form-control mb-2"
                      invalid={errors.password ? true : false}
                      placeholder=""
                      id="password"
                      name="password"
                      onChange={(e) => onChangeValue(e)}
                    />
                    <FormFeedback>{errors.password}</FormFeedback>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-2">DeepTherapy Link</label>
                    <Input
                      type="url"
                      className="form-control mb-2"
                      invalid={errors.zoom_link ? true : false}
                      placeholder=""
                      name="zoom_link"
                      value={zoom_link}
                      onChange={(e) => onChangeValue(e)}
                    />
                    <FormFeedback>{errors.zoom_link}</FormFeedback>
                  </div>
                </div> */}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mt-md-5 mt-3"
              onClick={(e) => submitUserProfile(e)}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
