import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../Components/PageTitle";
import AxiosApi from "../Helper/axios.api";
import { toast } from "react-toastify";
import SubscriptionItem from "../Components/SubscriptionItem";

export default function SubscriptionPlan() {
  const auth = useSelector((user) => user.auth);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  useEffect(() => {
    (async () => {
      await getSubscriptionPlans();
    })();

    return function () {};
  }, [isUpdate]);
  const getSubscriptionPlans = async () => {
    setLoading(true);
    await AxiosApi.withToken(auth.accessToken, "products", "get", {})
      .then(async (res) => {
        let products = res.data.data;
        if (res.status) {
          await Promise.all(
            products.map(async (item) => {
              await AxiosApi.withToken(
                auth.accessToken,
                "products/price/" + item.default_price,
                "get"
              )
                .then(async (res) => {
                  console.log(res);
                  if (res.status) {
                    item.price = res.data.unit_amount;
                  } else {
                    toast.error(res.message, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                })
                .catch((errors_res) => {
                  console.log(errors_res);
                });
            })
          );
          console.log(products, "new products");
          setSubscriptionPlans(products.reverse());
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  return (
    <>
      <PageTitle title="Subscription Plans" />
      <div className="container">
        <div id={loading ? "pageLoader" : ""} />
        <h1 className="text-center pricing-table-title">Subscription Plans</h1>
        <div className="row">
          {subscriptionPlans.length > 0
            ? subscriptionPlans.map((plan, index) => (
                <SubscriptionItem key={index} number={index} {...plan} />
              ))
            : ""}
        </div>
      </div>
    </>
  );
}
