import { React, useEffect, useState } from "react";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageTitle from "../Components/PageTitle";
import AxiosApi from "../Helper/axios.api";
import Einstein from "../assets/images/einstein-img.png";
import Arrow from "../assets/images/select-arrow.svg";
import asc from "../assets/images/sort_asc.png";
import desc from "../assets/images/sort_desc.png";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../Action/Auth.action";
import Info from "../assets/images/info-icon.svg";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";

export default function History() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function getScreenshotOfElement(
    element,
    posX,
    posY,
    width,
    height,
    callback
  ) {
    html2canvas(element, {
      onrendered: function (canvas) {
        var context = canvas.getContext("2d");
        var imageData = context.getImageData(posX, posY, width, height).data;
        var outputCanvas = document.createElement("canvas");
        var outputContext = outputCanvas.getContext("2d");
        outputCanvas.width = width;
        outputCanvas.height = height;

        var idata = outputContext.createImageData(width, height);
        idata.data.set(imageData);
        outputContext.putImageData(idata, 0, 0);
        callback(
          outputCanvas.toDataURL().replace("data:image/png;base64,", "")
        );
      },
      width: width,
      height: height,
      useCORS: true,
      taintTest: false,
      allowTaint: false,
    });
  }
  const exportPDF = (html) => {
    try {
      const doc = new jsPDF("p", "pt", "letter");
      doc.html(ReactDOMServer.renderToString(html.body), {
        callback: function (doc) {
          console.log(doc);
          doc.save("invoice.pdf");
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const auth = useSelector((user) => user.auth);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [trastionIsUpdate, setTrastionIsUpdate] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [totalCoins, setTotalCoins] = useState("");
  const [pages, setPages] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [params, setParams] = useState({
    user_id: auth.userDeatils.user_id,
    search_value: "",
    field_name: "created_at",
    order_value: "desc",
    current_time: new Date(),
    page: 1,
    per_page: 10,
  });
  const [subscriptionIsUpdate, setSubscriptionIsUpdate] = useState(false);
  const [subscriptionpages, setSubscriptionPages] = useState(0);
  const [subscriptionstartPage, setSubscriptionStartPage] = useState(1);
  const [subscriptionstartIndex, setSubscriptionStartIndex] = useState(0);
  const [subscriptionendIndex, setSubscriptionEndIndex] = useState(0);
  const [subscriptionendPage, setSubscriptionEndPage] = useState(0);
  const [subscriptiontotalPages, setSubscriptionTotalPages] = useState(0);
  const [subscriptiontotalRows, setSubscriptionTotalRows] = useState(0);
  const [subscriptionparams, setSubscriptionParams] = useState({
    user_id: auth.userDeatils.user_id,
    search_value: "",
    field_name: "create_at",
    order_value: "desc",
    current_time: new Date(),
    page: 1,
    per_page: 10,
  });

  useEffect(() => {
    (async () => {
      await getTransaction();
      await getSubscription();
    })();

    return function () {};
  }, [isUpdate]);
  useEffect(() => {
    (async () => {
      await getTransaction();
    })();

    return function () {};
  }, [trastionIsUpdate, params.page, params.per_page]);
  const getTransaction = async (e) => {
    // let params = {}
    // params.user_id = auth.userDeatils.user_id;
    // params.page = 1;
    // params.per_page = 100;
    await AxiosApi.withToken(
      auth.accessToken,
      "user/transaction",
      "post",
      params
    )
      .then(async (res) => {
        if (res.status) {
          // setAnimations(res.data.template);
          // setEndPage(res.data.pagination.last_pages);

          // setTotalRows(res.data.pagination.total);
          // getPager(res.data.pagination.total, res.data.pagination.current_page, params.per_page, res.data.pagination.last_pages)
          setTransaction(res.data.transaction);
          setEndPage(res.data.pagination.last_pages);
          setTotalRows(res.data.pagination.total);
          getPager(
            res.data.pagination.total,
            res.data.pagination.current_page,
            params.per_page,
            res.data.pagination.last_pages
          );
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };

  function getPager(totalItems, currentPage, pageSize, last_pages) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(last_pages + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    setStartIndex(startIndex);
    setEndIndex(endIndex);
    setPages(pages);
    setTotalPages(totalPages);
  }
  const requestSort = (key) => {
    let direction = "asc";
    if (params && params.field_name === key && params.order_value === "asc") {
      direction = "desc";
    }
    setParams({
      ...params,
      field_name: key,
      order_value: direction,
    });

    //setIsUpdate(!isUpdate);
    setTrastionIsUpdate(!trastionIsUpdate);
  };
  const getClassNamesFor = (name) => {
    if (!params) {
      return Arrow;
    }
    if (params.field_name === name) {
      if (params.order_value == "asc") {
        return asc;
      } else {
        return desc;
      }
    } else {
      return Arrow;
    }
  };

  const onParamChange = (e) => {
    let value = e.target.value;
    setParams({
      ...params,
      [e.target.name]: value,
    });
    setTrastionIsUpdate(!trastionIsUpdate);
  };
  const setPagenumber = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  const getSubscription = async (e) => {
    await AxiosApi.withToken(
      auth.accessToken,
      "subscription/user/history",
      "post",
      subscriptionparams
    )
      .then(async (res) => {
        if (res.status) {
          setSubscription(res.data.user_subscriptions);
          setSubscriptionEndPage(res.data.pagination.last_pages);

          setSubscriptionTotalRows(res.data.pagination.total);
          getSubscriptionPager(
            res.data.pagination.total,
            res.data.pagination.current_page,
            subscriptionparams.per_page,
            res.data.pagination.last_pages
          );
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (!res.login) {
            dispatch(logout());
            navigate("/login", { replace: true });
          }
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  };
  function getSubscriptionPager(totalItems, currentPage, pageSize, last_pages) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(last_pages + 1 - subscriptionstartPage).keys()].map(
      (i) => subscriptionstartPage + i
    );

    setSubscriptionStartIndex(startIndex);
    setSubscriptionEndIndex(endIndex);
    setSubscriptionPages(pages);
    setSubscriptionTotalPages(totalPages);
  }

  const SubscriptionrequestSort = (key) => {
    let direction = "asc";
    if (
      subscriptionparams &&
      subscriptionparams.field_name === key &&
      subscriptionparams.order_value === "asc"
    ) {
      direction = "desc";
    }
    setSubscriptionParams({
      ...subscriptionparams,
      field_name: key,
      order_value: direction,
    });

    //setIsUpdate(!isUpdate);
    setSubscriptionIsUpdate(!subscriptionIsUpdate);
  };
  const getSubscriptionClassNamesFor = (name) => {
    if (!subscriptionparams) {
      return Arrow;
    }
    if (subscriptionparams.field_name === name) {
      if (subscriptionparams.order_value == "asc") {
        return asc;
      } else {
        return desc;
      }
    } else {
      return Arrow;
    }
  };

  const SubscriptiononParamChange = (e) => {
    let value = e.target.value;
    setSubscriptionParams({
      ...subscriptionparams,
      [e.target.name]: value,
    });
    setSubscriptionIsUpdate(!subscriptionIsUpdate);
  };
  const setSubscriptionPagenumber = (page) => {
    setSubscriptionParams({
      ...subscriptionparams,
      page,
    });
  };
  useEffect(() => {
    (async () => {
      await getSubscription();
    })();

    return function () {};
  }, [
    subscriptionIsUpdate,
    subscriptionparams.page,
    subscriptionparams.per_page,
  ]);
  const handle_receipt = async (session_id) => {
    await AxiosApi.withToken(
      auth.accessToken,
      "products/session/" + session_id,
      "get"
    )
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          toast.success("invoice is downloaded", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
        }
      })
      .catch((errors_res) => {
        console.log(errors_res);
      });
  };
  useEffect(() => {
    setTotalCoins(auth.total_coin);
  }, [auth.total_coin]);
  return (
    <div className="virtualization">
      <div className="card w-100 h-100">
        <div className="card-header bg-white d-flex align-items-center justify-content-between">
          <div className="card-header-left">
            <div className="card-header-top d-flex align-items-center mb-2">
              <div className="card-title">Transaction history</div>
              <div className="credit-remaining-text px-3">
                <strong>{totalCoins ? totalCoins : ""}</strong>{" "}
                <span className="text-gray">credits remaining</span>
              </div>
              <div className="credit-info-col tooltip">
                <span
                  className="credit-info-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={totalCoins ? totalCoins + " credits remaining" : ""}
                >
                  <img src={Info} alt="Info" />
                  <span className="tooltiptext">
                    Animation = 1 credit per face
                    <br />
                    DeepTherapy session = 20 credits per 30 mins
                  </span>
                </span>
              </div>
              <div className="more-credits-col ps-3">
                <Link to={"/subscription"}>Get more credits</Link>
              </div>
            </div>
            <div className="card-header-bottom d-flex align-items-center"></div>
          </div>
        </div>
        <div className="card-body">
          <PageTitle title="DeepTherapy | History" />
          {/* <div className="container">
                <div className="row m-0"> */}
          <Tabs
            defaultActiveKey="Subscription"
            transition={false}
            id="noanim-tab-example"
            className=""
          >
            <Tab eventKey="Subscription" title="Subscription History">
              <div className="card-header bg-white d-flex align-items-center justify-content-between">
                <div className="card-header-left">
                  <div className="card-header-top d-flex align-items-center mb-2">
                    <div className="card-title">My Subscription</div>
                  </div>
                </div>
                <div className="search-col">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      type="text"
                      name="search_value"
                      id="search_value"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => SubscriptiononParamChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ minWidth: "500px" }}>
                <div className="animation-thead d-flex">
                  <div
                    className="animation-th w-20 d-flex align-items-center justify-content-between"
                    onClick={() =>
                      SubscriptionrequestSort("subscription_plan__name")
                    }
                  >
                    Plan
                    <img
                      src={getSubscriptionClassNamesFor(
                        "subscription_plan__name"
                      )}
                      alt="Arrow"
                    />
                  </div>
                  <div
                    className="animation-th w-15 d-flex align-items-center justify-content-between"
                    onClick={() =>
                      SubscriptionrequestSort("subscription_plan__price")
                    }
                  >
                    Price
                    <img
                      src={getSubscriptionClassNamesFor(
                        "subscription_plan__price"
                      )}
                      alt="Arrow"
                    />
                  </div>
                  <div
                    className="animation-th w-15 d-flex align-items-center justify-content-between"
                    onClick={() => SubscriptionrequestSort("number_coin")}
                  >
                    Credit
                    <img
                      src={getSubscriptionClassNamesFor("number_coin")}
                      alt="Arrow"
                    />
                  </div>
                  <div
                    className="animation-th w-20 d-flex align-items-center justify-content-between"
                    onClick={() => SubscriptionrequestSort("create_at")}
                  >
                    Date
                    <img
                      src={getSubscriptionClassNamesFor("create_at")}
                      alt="Arrow"
                    />
                  </div>
                  <div
                    className="animation-th d-flex align-items-center justify-content-between w-20"
                    onClick={() => SubscriptionrequestSort("transaction_id")}
                  >
                    Transaction#
                    <img
                      src={getSubscriptionClassNamesFor("transaction_id")}
                      alt="Arrow"
                    />
                  </div>
                  <div className="animation-th d-flex align-items-center justify-content-between w-10">
                    Action
                  </div>
                </div>
                <div className="animation-tbody">
                  {subscription.length > 0 ? (
                    subscription.map((row, index) => (
                      <div
                        key={index}
                        className="animation-tr d-flex align-items-center"
                      >
                        <div className="animation-td w-20">
                          {row.subscription_plan_name
                            ? row.subscription_plan_name
                            : ""}
                        </div>
                        <div className="animation-td w-15">
                          {/* <span className="animation-name"> */}€
                          {row.subscription_plan_price
                            ? row.subscription_plan_price
                            : ""}
                          {/* </span> */}
                        </div>
                        <div className="animation-td w-15">
                          {row.number_coin ? row.number_coin : ""}
                        </div>
                        <div className="animation-td w-20">
                          {row.create_at
                            ? moment(row.create_at)
                                .local()
                                .format("MMM D, YYYY hh:mm A")
                            : ""}
                        </div>
                        <div className="animation-td w-20">
                          {row.transaction_id ? row.transaction_id : ""}
                        </div>
                        <div className="animation-td history-status-col w-10">
                          <span
                            className="animation-status"
                            style={{
                              backgroundColor: "#1c5db4",
                              cursor: "pointer",
                            }}
                            title="Download invoice"
                            onClick={(e) => handle_receipt(row.transaction_id)}
                          >
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="animation-tr d-flex align-items-center">
                      <div className="animation-td animation-col">
                        No Records Founds.
                      </div>
                    </div>
                  )}
                  <div className="pagination-row">
                    {subscriptiontotalRows <=
                    subscriptionparams.per_page ? null : (
                      <div className="pagination justify-content-center">
                        {/* <span>Showing {startIndex + 1} - {endIndex + 1} of {totalRows}</span> */}
                        <ul className="pagination" style={{ paddingTop: "1%" }}>
                          <li
                            className={
                              subscriptionparams.page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() => setSubscriptionPagenumber(1)}
                            >
                              <i
                                className="fa fa-fast-backward"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li
                            className={
                              subscriptionparams.page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() =>
                                setSubscriptionPagenumber(
                                  subscriptionparams.page - 1
                                )
                              }
                            >
                              <i
                                className="fa fa-chevron-left"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          {subscriptionpages.length > 0
                            ? subscriptionpages.map((page, index) => (
                                <li
                                  key={index}
                                  role="button"
                                  className={
                                    subscriptionparams.page === page
                                      ? "page-item active"
                                      : "page-item"
                                  }
                                >
                                  <a
                                    className="page-link"
                                    onClick={() =>
                                      setSubscriptionPagenumber(page)
                                    }
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))
                            : ""}
                          <li
                            className={
                              subscriptionparams.page === subscriptiontotalPages
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() =>
                                setSubscriptionPagenumber(
                                  subscriptionparams.page + 1
                                )
                              }
                            >
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li
                            className={
                              subscriptionparams.page === subscriptiontotalPages
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() =>
                                setSubscriptionPagenumber(
                                  subscriptiontotalPages
                                )
                              }
                            >
                              <i
                                className="fa fa-fast-forward"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Transaction" title="Transaction History">
              <div className="card-header bg-white d-flex align-items-center justify-content-between">
                <div className="card-header-left">
                  <div className="card-header-top d-flex align-items-center mb-2">
                    <div className="card-title">My Transactions</div>
                  </div>
                </div>
                <div className="search-col">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      type="text"
                      name="search_value"
                      id="search_value"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => onParamChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ minWidth: "500px" }}>
                <div className="animation-thead d-flex">
                  <div
                    className="animation-th w-25 d-flex align-items-center justify-content-between"
                    onClick={() => requestSort("type")}
                  >
                    Type
                    <img src={getClassNamesFor("type")} alt="Arrow" />
                  </div>
                  <div
                    className="animation-th w-25 d-flex align-items-center justify-content-between"
                    onClick={() => requestSort("coin")}
                  >
                    Credit
                    <img src={getClassNamesFor("coin")} alt="Arrow" />
                  </div>
                  <div
                    className="animation-th w-25 d-flex align-items-center justify-content-between"
                    onClick={() => requestSort("reference_type")}
                  >
                    Reference
                    <img src={getClassNamesFor("reference_type")} alt="Arrow" />
                  </div>
                  <div
                    className="animation-th w-25 d-flex align-items-center justify-content-between"
                    onClick={() => requestSort("created_at")}
                  >
                    Date
                    <img src={getClassNamesFor("created_at")} alt="Arrow" />
                  </div>
                </div>
                <div className="animation-tbody">
                  {transaction.length > 0 ? (
                    transaction.map((row, index) => (
                      <div
                        key={index}
                        className="animation-tr d-flex align-items-center"
                      >
                        <div className="animation-td w-25">
                          {row.type ? row.type : ""}
                        </div>
                        <div className="animation-td w-25">
                          {/* <span className="animation-name"> */}
                          {row.coin ? row.coin : ""}
                          {/* </span> */}
                        </div>
                        <div className="animation-td w-25">
                          {row.reference_type ? row.reference_type : ""}
                        </div>
                        <div className="animation-td w-25">
                          {row.created_at
                            ? moment(row.created_at)
                                .local()
                                .format("MMM D, YYYY hh:mm A")
                            : ""}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="animation-tr d-flex align-items-center">
                      <div className="animation-td animation-col">
                        No Records Founds.
                      </div>
                    </div>
                  )}
                  <div className="pagination-row">
                    {totalRows <= params.per_page ? null : (
                      <div className="pagination justify-content-center">
                        {/* <span>Showing {startIndex + 1} - {endIndex + 1} of {totalRows}</span> */}
                        <ul className="pagination" style={{ paddingTop: "1%" }}>
                          <li
                            className={
                              params.page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() => setPagenumber(1)}
                            >
                              <i
                                className="fa fa-fast-backward"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li
                            className={
                              params.page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() => setPagenumber(params.page - 1)}
                            >
                              <i
                                className="fa fa-chevron-left"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          {pages.length > 0
                            ? pages.map((page, index) => (
                                <li
                                  key={index}
                                  role="button"
                                  className={
                                    params.page === page
                                      ? "page-item active"
                                      : "page-item"
                                  }
                                >
                                  <a
                                    className="page-link"
                                    onClick={() => setPagenumber(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))
                            : ""}
                          <li
                            className={
                              params.page === totalPages
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() => setPagenumber(params.page + 1)}
                            >
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li
                            className={
                              params.page === totalPages
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              onClick={() => setPagenumber(totalPages)}
                            >
                              <i
                                className="fa fa-fast-forward"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* <div className="animation-tr d-flex align-items-center">
                                        <div className="animation-td w-25">
                                            <img src={Einstein} alt="Einstein" />
                                        </div>
                                        <div className="animation-td w-25">
                                            <span className="animation-name">Einstein</span>
                                            <span className="animation-reaction">Happy</span>
                                        </div>
                                        <div className="animation-td w-25">Apr 9, 2022 4:39 PM</div>
                                        <div className="animation-td w-25">
                                            <span className="agenda-status">Session is in progress...</span>
                                            <a href="#">Open deeptherapy session -</a>
                                        </div>

                                    </div>
                                    <div className="animation-tr d-flex align-items-center">
                                        <div className="animation-td w-25">
                                            <img src={Einstein} alt="Einstein" />
                                        </div>
                                        <div className="animation-td w-25">
                                            <span className="animation-name">Einstein</span>
                                            <span className="animation-reaction">Happy</span>
                                        </div>
                                        <div className="animation-td w-25">Apr 9, 2022 4:39 PM</div>
                                        <div className="animation-td w-25">
                                            <span>Apr 30, 2022 4:39 PM</span>
                                            <a href="#">Update schedule </a>
                                        </div>

                                    </div>
                                    <div className="animation-tr d-flex align-items-center">
                                        <div className="animation-td w-25">
                                            <img src={Einstein} alt="Einstein" />
                                        </div>
                                        <div className="animation-td w-25">
                                            <span className="animation-name">Einstein</span>
                                            <span className="animation-reaction">Happy</span>
                                        </div>
                                        <div className="animation-td w-25">Apr 9, 2022 4:39 PM</div>
                                        <div className="animation-td w-25">
                                            <span>
                                                <strong>tomorrow</strong> at <strong>09.30 AM</strong>
                                            </span>
                                            <a href="#">Update schedule -</a>
                                        </div>

                                    </div>
                                    <div className="animation-tr d-flex align-items-center">
                                        <div className="animation-td w-25">
                                            <img src={Einstein} alt="Einstein" />
                                        </div>
                                        <div className="animation-td w-25">
                                            <span className="animation-name">Einstein</span>
                                            <span className="animation-reaction">Happy</span>
                                        </div>
                                        <div className="animation-td w-25">Apr 9, 2022 4:39 PM</div>
                                        <div className="animation-td w-25">
                                            <span>
                                                <strong>in 2 hours </strong>
                                            </span>
                                            <a href="#">Update schedule -</a>
                                        </div>

                                    </div> */}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
