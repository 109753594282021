import React from "react";
import { Routes, Route } from "react-router-dom";
// import Frontendlayout from "../Components/Frontend/Layout/Frontendlayout";
import Login from "../Pages/Auth/Login";
import Signup from "../Pages/Auth/Signup";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";



export default function LoginRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="signup" element={<Signup />}></Route>
        <Route path="forgot_password" element={<ForgotPassword />}></Route>
        <Route path="reset_password/:uid/:token" element={<ResetPassword />}></Route>
        <Route path="*" element={<Login />}></Route>
      </Routes>
    </>
  );
}
