import { React, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import AxiosApi from "../../Helper/axios.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RecordRTC, { invokeSaveAsDialog, StereoAudioRecorder } from 'recordrtc';
import '@material/react-linear-progress/dist/linear-progress.css';
import LinearProgress from '@material/react-linear-progress';
export default function NotStartedSession(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [toggleMicCamera, setToggleMicCamera] = useState(true);
  const [stopSession, setStopSession] = useState(true);
  const [joinSession, setJoinSession] = useState(false);
  const [toggleMicCameraon, setToggleMicCameraon] = useState(true);
  const [errormessage, setErrormessage] = useState("");
  const [recorder, setRecorder] = useState(null);
  const [starttime, setStarttime] = useState(null);
  const [endtime, setEndtime] = useState(null);
  const [countupdate, setCountupdate] = useState(0);
  const [autochange, setAutochange] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const [workerURL, setWorkerURL] = useState(`https://s1.${window.location.hostname}`);

  const auth = useSelector((user) => user.auth);
  const [state, setState] = useState({
    sessionHead: "",
    sessionName: "",
    zoomLink: "",
    duration: "",
    errors: {},
  });
  let rec;
  const { sessionHead, sessionName, duration, zoomLink, errors } = state;
  useEffect(() => {
    (async () => {
      if (props.id && props.id != '') {
        await getreservation(props.id);
      }

    })()


    return function () {
    };
  }, [props.id, isUpdate])

  useEffect(() => {
    updateduresion()
    return function () {
    };
  }, [countupdate])
  function diffYMDHMS(date1, date2) {
    var string_data = "";
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    if (years > 0) {
      string_data += years + "Y";
    }
    if (months > 0) {
      string_data += " " + months + "M";
    }
    if (days > 0) {
      string_data += " " + days + "d";
    }
    if (hours > 0) {
      string_data += " " + hours + "h";
    }
    if (minutes > 0) {
      string_data += " " + minutes + "m";
    }
    if (seconds > 0) {
      string_data += " " + seconds + "s";
    }
    return string_data;
  }
  const updateduresion = async () => {

    if (autochange) {
      if (zoomLink !== '') {

        var date2 = new Date();
        let current_time = moment(date2).format("yyyy-MM-DD HH:mm");
        let start_time = moment(starttime).format("yyyy-MM-DD HH:mm");
        let result = moment(current_time).isBefore(start_time);  // true
        var now = moment(new Date()); //todays date
        var end = moment(starttime); // another date
        let diff = await diffYMDHMS(end, now);
        if (result) {
          setState({
            ...state,
            sessionHead: "Your live virtual session hasn’t started yet",
            zoomLink: zoomLink,
            sessionName: sessionName,
            duration: "start in " + diff

          })
          //setAutochange(true)
          setCountupdate(countupdate + 1)
        } else {
          let time_end = moment(endtime).format("yyyy-MM-DD HH:mm");
          let time_end_result = moment(current_time).isSameOrBefore(time_end);  // true
          var now = moment(new Date()); //todays date
          var end = moment(endtime); // another date
          let diff = await diffYMDHMS(end, now);
          if (time_end_result && diff != '') {
            setState({
              ...state,
              sessionHead: "Session is in progress",
              zoomLink: zoomLink,
              sessionName: sessionName,
              duration: "remaining time: " + diff
            })
             setIsRunning(true)
            //setAutochange(true)
            setCountupdate(countupdate + 1)
          } else {
            if (isRunning) {
              setIsRunning(false)
              setAutochange(false)
              stopSessionclick()
              toast.error("This live virtual session is expired", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              close_model()
            }
            setState({
              ...state,
              sessionHead: "This live virtual session is expired",
              duration: moment(endtime).format("MMM DD,yyyy hh:mm A"),
              zoomLink: zoomLink,
              sessionName: sessionName
            })

            setCountupdate(countupdate + 1)
          }

        }

      }
    }
  }
  const getreservation = async (reservation_id) => {
    setLoading(true)
    await AxiosApi.withToken(auth.accessToken, "reservations/" + reservation_id + "/details", "get", {})
      .then(async (res) => {
        // var diff = Math.abs(new Date(res.data.time_start) - new Date(res.data.time_end));
        // var minutes = Math.floor((diff / 1000) / 60);

        if (res.status) {
          setStarttime(res.data.time_start)
          setEndtime(res.data.time_end)

          // clearInterval(timerId)
          var date2 = new Date();
          let current_time = moment(date2).format("yyyy-MM-DD HH:mm");
          let start_time = moment(res.data.time_start).format("yyyy-MM-DD HH:mm");
          let result = moment(current_time).isBefore(start_time);  // true
          var now = moment(new Date()); //todays date
          var end = moment(res.data.time_start); // another date
          let diff = await diffYMDHMS(end, now);
          if (result) {
            setState({
              ...state,
              sessionHead: "Your live virtual session hasn’t started yet",
              zoomLink: res.data.zoom_link,
              sessionName: res.data.name,
              duration: "start in " + diff
            })
            setAutochange(true)
            setCountupdate(countupdate + 1)
          } else {
            let time_end = moment(res.data.time_end).format("yyyy-MM-DD HH:mm");
            let time_end_result = moment(current_time).isSameOrBefore(time_end);  // true
            var now = moment(new Date()); //todays date
            var end = moment(res.data.time_end); // another date
            let diff = await diffYMDHMS(end, now);
            if (time_end_result) {
              setState({
                ...state,
                sessionHead: "Session is in progress",
                zoomLink: res.data.zoom_link,
                sessionName: res.data.name,
                duration: "remaining time " + diff
              })
              setIsRunning(true)
              setAutochange(true)
              setCountupdate(countupdate + 1)
            } else {
              setState({
                ...state,
                sessionHead: "This live virtual session is expired",
                zoomLink: res.data.zoom_link,
                duration: moment(res.data.time_end).format("MMM DD,yyyy hh:mm A"),
                sessionName: res.data.name
              })
              setIsRunning(false)
              setAutochange(false)
              setCountupdate(countupdate + 1)
            }
          }



        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((errors_res) => {
        setLoading(false);
      });
  }
  const close_model = () => {
    setIsRunning(false)
    setAutochange(false)
    setCountupdate(countupdate)
    props.setModal(false)
    setToggleMicCamera(true)
    setStopSession(true)
    setJoinSession(false)

    if (props.dashboard) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/sessions", { replace: true });
    }


  }
  const reload_toggal_modal = async () => {
    // setIsUpdate(!isUpdate)
    window.location.reload();
  }
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  const join_session = async () => {

    // let parameter = {}
    // parameter.reservation_id = props.id
    setLoading(true);
    setJoinSession(true)
    let parameter = new FormData();
    parameter.append("reservation_id", props.id);
    AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/update_face`, "post", parameter).then(async (face) => {
      if (face.status == 200) {

        navigator.mediaDevices.getUserMedia({ audio: true, noiseSuppression: true }).then(async (stream) => {
          let blobs = [];
          let fds = []
          let blob_id = 0;
          rec = await RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm',
            sampleRate: 44100, // 44100,
            // recorderType: 'audio',
            recorderType: StereoAudioRecorder,
            numberOfAudioChannels: 1,
            timeSlice: 250,
            // as soon as the stream is available
            ondataavailable: function (blob) {
              blob_id = blob_id + 1;
              var fd = new FormData();
              fd.append('data', new Blob([[pad(props.id, 36), pad(blob_id, 8)], blob]));
              AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/record`, "post", fd.get("data"));
              let is_first = false;
            }
          });
          rec.startRecording();
          setRecorder(rec)
          let join_data = new FormData();
          join_data.append("zoom_link", zoomLink);
          join_data.append("reservation_id", props.id);

          AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/join`, "post", join_data).then((response) => {
            if (response.status == 200) {
              setToggleMicCamera(false)
              setStopSession(false)
              setLoading(false);
            }

          })

        }).catch((errors_res) => {
          setLoading(false);
          setJoinSession(false)
        });
      }
    }).catch((errors_res) => {
      setLoading(false);
      setJoinSession(false)
    });

  }
  function stop_session(silent = true) {
    if (silent == false) {
      let parameter = new FormData();
      parameter.append("reservation_id", props.id);

      AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/stop_recording`, "post", parameter).then(async (res) => {
        setJoinSession(false)
        setToggleMicCameraon(true)
      })

    }
  }
  const toggleMicCameraclick = async () => {
    let parameter = new FormData();
    parameter.append("reservation_id", props.id);

    AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/toggle_camera`, "post", parameter);
    AxiosApi.withoutTokenFullUrl(`${workerURL}/api/v1/toggle_microphone`, "post", parameter);
    setToggleMicCameraon(!toggleMicCameraon)
  }
  const stopSessionclick = async () => {
    if (recorder) {
      recorder.stopRecording(() => {
        recorder.destroy();
        setRecorder(null);
        setToggleMicCamera(true)
        setStopSession(true)
        setJoinSession(false)
        stop_session(false);
      });

    }
  }
  return (
    <>

      <Modal
        // size="lg"
        show={props.modal}
        // onHide={() => {
        //   setIsRunning(false)
        //   setAutochange(false)
        //   close_model()
        // }
        
        // }
        fullscreen={true}
        // aria-labelledby="example-modal-sizes-title-lg"
        // animation={true}
        style={{ zIndex: "999999" }}
      >
        <Modal.Header style={{ border: 'none' }}>

          {
            isRunning ? (
              <div style={{ position: "absolute", left: '10px', top: '10px' }}>
                <button
                  type="button"
                  className="modalOpenBtn btn bg-gradient-success pull-right"
                  data-bs-toggle="modal"
                  onClick={() => reload_toggal_modal()}
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-refresh"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            ) : ""
          }
          {/* <div style={{ position: "absolute", right: '0' }}>
            <button
              type="button"
              className="modalOpenBtn btn bg-gradient-success pull-right"
              data-bs-toggle="modal"
              onClick={() => {
                setIsRunning(false)
                setAutochange(false)
                close_model()
              }
              }
            >
              <i
                style={{ fontSize: 20 }}
                className="fa fa-times"
                aria-hidden="true"
              ></i>
            </button>
          </div> */}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex mh-100 h-100 justify-content-center align-items-center">
            <div className="text-center d-flex flex-column" style={{gap: "2rem"}}>
              <h2 style={{ fontFamily: "Inter", fontSize: "28px", fontWeight: "700" }}>{sessionHead}</h2>
              
              <div className="d-flex flex-column" style={{gap: "0.128rem"}}>
                <span style={{ fontFamily: "Inter", fontSize: "28px", fontWeight: "700" }}>" {sessionName} "</span>
                <span>{duration}</span>
              </div>

              {/* <div className="d-flex flex-column" style={{gap: "0.128rem"}}>
                <span style={{ fontWeight: "700" }}>DeepTherapy link</span>
                <span style={{ color: "#A0A0A0" }}>{zoomLink}</span>
              </div> */}
              
              {
                loading ? <LinearProgress /> : ""
              }
              {
                isRunning ? (
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="btn-primary me-2"
                      onClick={() => join_session()}
                      disabled={joinSession}
                    >
                      Join session
                    </Button>
                    {
                      toggleMicCameraon ?
                        <Button
                          variant="contained"
                          className="btn-primary me-2"
                          disabled={toggleMicCamera}
                          onClick={() => toggleMicCameraclick()}
                        >
                          Toggle on Mic & Camera
                        </Button>
                        :
                        <Button
                          variant="contained"
                          className="btn-primary me-2"
                          disabled={toggleMicCamera}
                          onClick={() => toggleMicCameraclick()}
                        >
                          Toggle off Mic & Camera
                        </Button>
                    }

                    <Button
                      variant="contained"
                      className="btn-danger"
                      disabled={stopSession}
                      onClick={() => stopSessionclick()}
                    >
                      Stop session
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className="btn-primary"
                    onClick={() => {
                      setIsRunning(false)
                      setAutochange(false)
                      close_model()
                    }
                    }
                  >
                    Go back to schedule
                  </Button>
                )
              }
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
