import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { logout } from "../../../Action/Auth.action";
import { useLocation } from 'react-router-dom';

export default function Sidebar(props) {

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logOut = () => {
    confirmAlert({
      title: "Logout",
      message: "Are you sure to do logout ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(logout());
            navigate("/login", { replace: true });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <div className={props.showmenu ? "left-navbar left-navbar-show" : "left-navbar"}  >
        <ul className="navbar-items d-flex flex-column align-items-center list-unstyled">
          <li className={`navbar-item ${location.pathname === "/dashboard" ? "active" : ""}`}>
            <Link to="/dashboard" className="video-item" title={"My Animations"}>
              <i className="fa fa-video-camera text-white"></i>
            </Link>
          </li>
          <li className={`navbar-item ${location.pathname === "/sessions" ? "active" : ""}`}>
            <Link to="/sessions" className="calendar-item" title={"My Sessions"}>
              <i className="fa fa-calendar text-white"></i>
            </Link>
          </li>
          <li className={`navbar-item ${location.pathname === "/history" ? "active" : ""}`}>

            <Link to="/history" className="wallet-item" title={"My History"}>
              <i className="fa fa-tasks text-white"></i>
            </Link>

          </li>
          <li className={`navbar-item ${location.pathname === "/profile" ? "active" : ""}`}>
            <Link to="/profile" className="setting-item" title={"Settings"}>
              <i className="fa fa-cog text-white"></i>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
