import { React, useState, useEffect, useRef } from "react";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Input, Label, FormFeedback } from "reactstrap";
import helper from "../Helper/Helper";
import { validateAll } from "indicative/validator";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import AxiosApi from "../Helper/axios.api";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import calunderpen from '../assets/images/calunder-pen.png';
import zoom_logo_icon from '../assets/images/zoom_logo_icon.png';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
export default function UpdateAnimation(props) {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [minUpdateEventDate, setMinUpdateEventDate] = useState(moment.utc(new Date()).add(1, 'd').format());
    const [animation, setAnimation] = useState({
        id: '',
        name: '',
        img: '',
        thumb: '',
        video: ''
    });
    const submitSessionForm = (e) => {

    };
    const videoRef = useRef();
    const getanimation = async (template_id) => {
        await AxiosApi.withToken(auth.accessToken, "templates/" + template_id + "/details", "get", {})
            .then(async (res) => {
                if (res.status) {
                    setAnimation(res.data);
                    setSession(res.data.session);

                } else {
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            })
            .catch((errors_res) => {
                setLoading(false);
            });
    }
    useEffect(() => {
        (async () => {

            if (props.templeteid && props.templeteid != '') {
                await getanimation(props.templeteid);
                videoRef.current?.load();
            }

        })()

        return function () {
        };
    }, [props.templeteid, isUpdate])

    const updateAnimation = async () => {
        let data = {};
        data.user_id = auth.userDeatils.user_id;
        data.template_id = animation.id;
        data.name = animation.name;

        await AxiosApi.withToken(auth.accessToken, "templates/edit", "post", data)
            .then((res) => {
                if (res.status) {
                    toast.success(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setAnimation({
                        id: '',
                        name: '',
                        img: '',
                        thumb: '',
                        video: ''
                    })
                    setSession([])
                    props.settempleteid('')
                    props.setIsUpdate(!props.isUpdate);
                    props.setModal(false)

                } else {
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            })
            .catch((errors_res) => {
                setLoading(false);
            });
    }
    const deleteAnimation = (id) => {
        props.setModal(false)
        confirmAlert({
            title: "Animation",
            message: "Are you sure to do delete ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {

                        let data = {};
                        data.user_id = auth.userDeatils.user_id;
                        data.template_id = id;

                        await AxiosApi.withToken(auth.accessToken, "templates/delete", "post", data)
                            .then((res) => {
                                if (res.status) {
                                    //await get_animation();
                                    toast.success(res.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });

                                    setAnimation({
                                        id: '',
                                        name: '',
                                        img: '',
                                        thumb: '',
                                        video: ''
                                    })
                                    props.settempleteid('')
                                    props.setIsUpdate(!props.isUpdate);
                                    props.setModal(false)

                                } else {
                                    toast.error(res.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                                setLoading(false);
                            })
                            .catch((errors_res) => {
                                setLoading(false);
                            });

                    },
                },
                {
                    label: "No",
                    onClick: async () => {
                        props.setModal(true)
                    }
                },
            ],
        });
    }
    const onChangeValue = (e) => {
        let value = e.target.value;
        setAnimation({
            ...animation,
            [e.target.name]: value
        });
    };
    function diffYMDHMS(date1, date2, fortime) {
        var string_data = "";
        let years = date1.diff(date2, 'year');
        date2.add(years, 'years');

        let months = date1.diff(date2, 'months');
        date2.add(months, 'months');

        let days = date1.diff(date2, 'days');
        date2.add(days, 'days');

        let hours = date1.diff(date2, 'hours');
        date2.add(hours, 'hours');

        let minutes = date1.diff(date2, 'minutes');
        date2.add(minutes, 'minutes');

        let seconds = date1.diff(date2, 'seconds');

        if (years > 0) {
            if (fortime) {
                return date1.local().format('MMM DD,YYYY h:mm A')
            } else {
                return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
            }

        }
        if (months > 0) {
            if (fortime) {
                return date1.local().format('MMM DD,YYYY h:mm A')
            } else {
                return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
            }
            // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
        }

        if (days > 0) {
            if (days == 1) {
                if (fortime) {
                    return "<b>Tomorrow</b> at <b>" + date1.local().format('h:mm A') + "</b>";
                } else {
                    return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
                }
                // return "<b>Tomorrow</b> at <b>" + date1.local().format('h:mm A') + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
            } else {
                if (fortime) {
                    return date1.local().format('MMM DD,YYYY h:mm A');
                } else {
                    return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
                }
                // return date1.local().format('MMM DD,YYYY h:mm A') + '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i><p>';
            }

        }

        if (hours > 0) {
            string_data += " " + hours + "h";
        }
        if (minutes > 0) {
            string_data += " " + minutes + "m";
        }
        if (seconds > 0) {
            string_data += " " + seconds + "s";
        }
        if (fortime) {
            return "<b>Start</b> in <b>" + string_data + "</b>";
        } else {
            return '<p style="color: rgb(28, 93, 180); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
        }
        // return "<b>Start</b> in <b>" + string_data + '</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Update Schedule <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
    }
    const get_session_schedule = (session_data, asStringTime) => {

        var date2 = new Date();
        let current_time = moment(date2).format("yyyy-MM-DD HH:mm");
        let start_time = moment(session_data.time_start).format("yyyy-MM-DD HH:mm");
        let isFuture = moment(current_time).isBefore(start_time);  // true
        if (isFuture) {
            var now = moment(new Date());
            var end = moment(session_data.time_start);
            let diff = diffYMDHMS(end, now, asStringTime);
            return diff;
        } else {
            if (asStringTime) {
                return "<b>Session is in Progress..</b>";
            } else {
                return '<p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>'
            }
            // return '<b>Session is in Progress..</b> <p style="color: rgb(0, 160, 254); cursor: pointer; font-weight:700;">Open deeptherapy session <i class="fa fa-arrow-right" aria-hidden="true"></i></p>';
        }

    }
    const isCurrentlyActive = (event_data) => {
        var new_data = new Date();
        let current_time = moment(new_data).format("yyyy-MM-DD HH:mm");
        let start_time = moment(event_data.time_start).format("yyyy-MM-DD HH:mm");
        let end_time = moment(event_data.time_end).format("yyyy-MM-DD HH:mm");        
        return moment(current_time).isAfter(start_time) && moment(current_time).isBefore(end_time)
    }
    const show_modal = async (event_data) => {
        if (isCurrentlyActive(event_data)) {
            window.open("/dashboard/" + event_data.id, event_data.id, 'width=700,height=400');
        }
    }

    const delete_reservations = async (reservation_id) => {
        setLoading(true);
        let data_params = {}
        data_params.user_id = auth.userDeatils.user_id;
        data_params.reservation_id = reservation_id;
    
        await AxiosApi.withToken(auth.accessToken, "reservations/delete", "post", data_params)
          .then(async (res) => {
            if (res.status) {
              setIsUpdate(!isUpdate)
              toast.success(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
    
            } else {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setLoading(false);
          })
          .catch((errors_res) => {
            setLoading(false);
          });
    }

    const mockup_sessions = (<>
        <div className="row" style={{ padding: '15px' }}>
            <div className="col-md-2 p-1 text-center" style={{ background: "#1c5db4" }}>
                <i className="fa fa-video-camera text-white" aria-hidden="true"></i>
                <span className="text-white">Zoom</span>
            </div>
            <div className="col-md-8">
                <p style={{ margin: '0', fontWeight: '600' }}>Meeting with obama</p>
                <span style={{ fontSize: '13px', fontWeight: '600' }}>session is in progress</span>
            </div>
        </div>
        <div className="row" style={{ padding: '15px' }}>
            <div className="col-md-2 p-1 text-center" style={{ background: "#1c5db4" }}>
                <i className="fa fa-video-camera text-white" aria-hidden="true"></i>
                <span className="text-white">Zoom</span>
            </div>
            <div className="col-md-8">
                <p style={{ margin: '0', fontWeight: '600' }}>Meeting with obama</p>
                <span style={{ fontSize: '13px', fontWeight: '600' }}>session is in progress</span>
            </div>
        </div>
        <div className="row" style={{ padding: '15px' }}>
            <div className="col-md-2 p-1 text-center" style={{ background: "#1c5db4" }}>
                <i className="fa fa-video-camera text-white" aria-hidden="true"></i>
                <span className="text-white">Zoom</span>
            </div>
            <div className="col-md-8">
                <p style={{ margin: '0', fontWeight: '600' }}>Meeting with obama</p>
                <span style={{ fontSize: '13px', fontWeight: '600' }}>session is in progress</span>
            </div>
        </div>
        <div className="row" style={{ padding: '15px' }}>
            <div className="col-md-2 p-1 text-center" style={{ background: "#1c5db4" }}>
                <i className="fa fa-video-camera text-white" aria-hidden="true"></i>
                <span className="text-white">Zoom</span>
            </div>
            <div className="col-md-8">
                <p style={{ margin: '0', fontWeight: '600' }}>Meeting with obama</p>
                <span style={{ fontSize: '13px', fontWeight: '600' }}>session is in progress</span>
            </div>
        </div>
        <div className="row" style={{ padding: '15px' }}>
            <div className="col-md-2 p-1 text-center" style={{ background: "#1c5db4" }}>
                <i className="fa fa-video-camera text-white" aria-hidden="true"></i>
                <span className="text-white">Zoom</span>
            </div>
            <div className="col-md-8">
                <p style={{ margin: '0', fontWeight: '600' }}>Meeting with obama</p>
                <span style={{ fontSize: '13px', fontWeight: '600' }}>session is in progress</span>
            </div>
        </div>
    </>
    )

    return (
        <>
            <Modal
                size="lg"
                show={props.modal}
                onHide={() => {
                    setAnimation({
                        id: '',
                        name: '',
                        img: '',
                        thumb: '',
                        video: ''
                    })
                    setSession([])
                    props.settempleteid('')
                    props.setModal(false)
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                className="update-animation-modal advanced-modal"
                animation={true}
                centered
                style={{ zIndex: "999999" }}
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div id={loading ? "pageLoader" : ""} />
                        <h5 className="modal-title">Update Animation</h5>
                    </Modal.Title>
                    <button
                        type="button"
                        className="modalOpenBtn btn bg-gradient-success pull-right"
                        data-bs-toggle="modal"
                        onClick={() => {
                            setAnimation({
                                id: '',
                                name: '',
                                img: '',
                                thumb: '',
                                video: ''
                            })
                            setSession([])
                            props.settempleteid('')
                            props.setModal(false)
                        }}
                    >
                        <i
                            style={{ fontSize: 20 }}
                            className="fa fa-times"
                            aria-hidden="true"
                        ></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex" style={{gap: '30px'}}>
                        <div className="col-md-5 video-col">
                            <video controls style={{ width: '100%', border: "5px solid black", borderRadius: "5px" }} ref={videoRef}>
                                <source src={`${helper.ApiBaseUrl}${animation.video}`} />
                            </video>
                        </div>
                        <div className="col-md-7 detail-col">
                            <div className="profile-form-block">
                                <Label className="form-label">Title</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    defaultValue={animation.name}
                                    onChange={(e) => onChangeValue(e)}
                                // defaultValue={image_name}
                                // invalid={errors.title ? true : false}
                                // onChange={(e) => onChangeValueState(e)}
                                />
                                {/* <FormFeedback>{errors.title}</FormFeedback> */}

                                <Label className="pt-3">Upcoming Session</Label>
                                <div className="row" >
                                    <div className="col-md-9 d-flex flex-column upcoming-session-list">
                                        {session.length > 0
                                            ? session.map((event_data, index) => (

                                                <div key={index} className={`d-flex session-item ${isCurrentlyActive(event_data) ? 'session-item-active':''}`} 
                                                    onClick={(e) => show_modal(event_data)}>
                                                    <div className="session-item-icon text-center">
                                                        {
                                                            minUpdateEventDate > event_data.time_start ? (
                                                                <img src={zoom_logo_icon} className="session-item-icon-image" alt="edit" />
                                                            ) : (
                                                                <img src={calunderpen} className="session-item-icon-image" alt="edit" />
                                                                
                                                            )
                                                        }
                                                    </div>
                                                    <div className="session-item-details d-flex flex-column justify-content-center">
                                                        <p className="session-item-details-title">{event_data.name}</p>
                                                        <a className="session-item-details-descriptions">
                                                            <span style={{ color: "#000", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: get_session_schedule(event_data, true) }}>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div className="session-item-actions">
                                                        {!isCurrentlyActive(event_data) ? 
                                                            <DropdownButton
                                                                variant={"bg-transparent"}
                                                                className="drp-navbar-name"
                                                                align="end"
                                                                title={
                                                                    <>
                                                                    <span style={{ fontWeight: "700" }}>...</span>
                                                                    </>
                                                                }
                                                                >

                                                                <Dropdown.Item onClick={() => {
                                                                    props.setModal(false)
                                                                    animation.mood = props.mood
                                                                    props.openschedule(event_data, animation)
                                                                }}>
                                                                    Update
                                                                </Dropdown.Item>


                                                                <Dropdown.Item onClick={() => delete_reservations(event_data.id)}>
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        : ""}
                                                    </div>
                                                </div>

                                            ))
                                            : (
                                                <p style={{ padding: '10px', background: '#f2f2f2', fontSize: '14px' }}>There is no upcoming session</p>
                                            )
                                        }
                                        { /* mockup_sessions */}

                                    </div>
                                    <div className="col-md-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary animation-action-button d-flex justify-content-center align-items-center"
                                            onClick={(e) => {                    
                                                props.setModal(false)
                                                animation.mood = props.mood
                                                props.createschedule(animation)
                                            }}
                                            title={"Schedule Animation"}
                                            style={{minHeight: '36px'}}
                                        >
                                            <i className="fa fa-calendar-plus" aria-hidden="true"></i>
                                            schedule
                                        </button>
                                    </div>
                                </div>
                                <div className="row pt-1">
                                    <div className="col-md-3 col-3">
                                        <button
                                            type="button"
                                            className="btn btn-warning text-white upcoming-session-action-button"
                                            // onClick={(e) => openModel(row)}
                                            title={"Delete"}
                                            onClick={(e) => deleteAnimation(animation.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div className="col-md-3 col-3">
                                        {/* <button
                                            type="button"
                                            className="btn btn-primary w-100"
                                            // onClick={(e) => openModel(row)}
                                            title={"Share Animation"}
                                        >
                                            Share
                                        </button> */}
                                        <DropdownButton
                                            className="upcoming-session-action-button"
                                            // eventKey={}
                                            title={
                                                <span>
                                                    {/* <i className="fa fa-share" aria-hidden="true"></i> */}
                                                    share</span>
                                            }
                                        >
                                            <Dropdown.Item>
                                                <TwitterShareButton
                                                    url={`${helper.ApiBaseUrl}`}
                                                    title={animation.name}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <i className="fa fa-twitter text-dark" aria-hidden="true"></i> Twitter
                                                </TwitterShareButton>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <FacebookShareButton
                                                    url={`${helper.ApiBaseUrl}`}
                                                    quote={animation.name}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <i className="fa fa-facebook text-dark" aria-hidden="true"></i> Facebook
                                                </FacebookShareButton>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <WhatsappShareButton
                                                    url={`${helper.ApiBaseUrl}`}
                                                    title={animation.name}
                                                    separator=":: "
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <i className="fa fa-whatsapp text-dark" aria-hidden="true"></i> Whatsapp
                                                </WhatsappShareButton>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <LinkedinShareButton url={`${helper.ApiBaseUrl}`} className="Demo__some-network__share-button">
                                                    <i className="fa fa-linkedin text-dark" aria-hidden="true"></i> LinkedIn
                                                </LinkedinShareButton>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <EmailShareButton
                                                    url={`${helper.ApiBaseUrl}`}
                                                    subject={animation.name}
                                                    body="body"
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <i className="fa fa-envelope-o text-dark" aria-hidden="true"></i> Email
                                                </EmailShareButton>
                                            </Dropdown.Item>


                                        </DropdownButton>
                                    </div>
                                    <div className="col-md-3 col-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary upcoming-session-action-button"
                                            // onClick={(e) => openModel(row)}
                                            title={"Download Animation"}
                                            onClick={(e) => helper.handleDownload(`${helper.ApiBaseUrl}${animation.video}`, animation.name, '.mp4')}
                                        >
                                            Download
                                        </button>
                                    </div>
                                    <div className="col-md-3 col-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary upcoming-session-action-button"
                                            onClick={(e) => updateAnimation()}
                                            title={"Save Animation"}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    );
}
