
import fileDownload from 'js-file-download'
import axios from "axios";
let API_URL;
let API_BASE_URL;
let STRIPE_KEY;
let SITEURL;

const current_domain = window.location.hostname
console.log('host: ', current_domain)

if(window.location.hostname === 'localhost'){
  //API_URL = 'https://cors-anywhere.herokuapp.com/localhost:8000/api/v2/'
  API_URL = 'https://dev.app.deeptherapy.ai/api/v3/'
  API_BASE_URL = 'https://dev.app.deeptherapy.ai/'
  STRIPE_KEY = 'pk_test_51L2zFLGrcsmAqh6FbX4UArximLtBtppsx92w1SdkJFkLW76TsVow433baPUNfWqK4cUHDihWifx8CHd80QyBYt2700EBaeimdv'
  SITEURL = 'https://dev.app.deeptherapy.ai'
} else {
  // API_URL = 'https://cors-anywhere.herokuapp.com/127.0.0.1:8000/api/v2/'
  API_URL = `https://${current_domain}/api/v3/`
  API_BASE_URL = `https://${current_domain}/`

  if (window.location.hostname.indexOf('dev.app') > -1) {
    STRIPE_KEY = 'pk_test_51L2zFLGrcsmAqh6FbX4UArximLtBtppsx92w1SdkJFkLW76TsVow433baPUNfWqK4cUHDihWifx8CHd80QyBYt2700EBaeimdv'
  } else {
    STRIPE_KEY = 'pk_live_51L2zFLGrcsmAqh6FPVsiO6ESuCFGdd1WWjhDGZjs9n8JBWmpuHRbb6Aj3F23auf1VtKMEw2Xys3esKXryYKmgPTC00iIpHIbZF'    
  }

  SITEURL = `https://${current_domain}`
}


let helper = {
  ApiUrl:API_URL,
  ApiBaseUrl:API_BASE_URL,
  STRIPE_KEY:STRIPE_KEY,
  siteUrl : SITEURL,
  userTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
  handleDownload : (url, filename,extension) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      var file_name = filename+extension;
      fileDownload(res.data, file_name)
    })
  }
};



export default helper;
